/* import React from 'react'; */
import React, { Component, useEffect } from 'react';

function Mobility (props) {
  useEffect(()=>{
    document.title = "Mobility | Vizonomy";
  },[]);
return (
<div className="Mobility">

</div>
);
}
export default Mobility;