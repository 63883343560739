/* import React from 'react'; */
import React, { useEffect  } from 'react';

function UserExperience (props) {
  useEffect(()=>{
    document.title = "User Experience | Vizonomy";
  },[]);
return (
<div className="UserExperience">
  <div className="sec-nr">
    <div className="bk-grid">
    </div>
    <div className="content-nr">
	  	  <div className="navi">
          <div className="side-a">
            <h3><span className="ic-gw"></span><span className="title">User Experience</span></h3>
          </div>
          <div className="side-b">
            <a href="/user-experience" className="active" >User Experience</a>
            <a href="/gis-web-mapping" >GIS & Web Mapping</a>
            <a href="/data-visualization" >Data Visualization</a>
            <a href="/mobile-web" >Mobile & Web</a>
            <a href="/augmented-reality">Augmented Reality</a>
          </div>
        </div>
        <div className="row mx-0 my-0 cover-detail">
          <div className="col-9">
            <h1 className="title-principal">Products designed with intent and simplicity</h1>
            <p className="description">
            Whether you have an in-house design team or no design team, our intention is to be design advisors and help derive a fresh perspective on digital design. Our work focuses on finer details in storytelling because we know from experience that they make all the difference.
            </p>
            <div class="op-link">
              <a href="/business-intelligence" class="btn-blue">
                <span class="op-link-title">Projects</span><span class="arrow-white"></span>
              </a>
              <a href="/contact-us" class="link-blue">
                <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-4">
          </div>
        </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/ux/bk-img-ux-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h4>User Experience</h4>
          <h1>Our design process</h1>
					<p>
					Successful products and services are not only visually appealing to your audience but are also highly tailored to your users’ needs. Using expert analysis based on widely renowned usability heuristics, established industry patterns, and the right design tools, our approach can ensure you are providing your users with the ultimate user experience and increase business value.
					</p>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-01"></span></div>
          <h4 class="turq-title-3">Simplify</h4>
          <p class="turq-description">Define user needs and pain points.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-02"></span></div>
          <h4 class="turq-title-3">Empathize</h4>
          <p class="turq-description">Understand features and priorities.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-03"></span></div>
          <h4 class="turq-title-3">Research</h4>
          <p class="turq-description">Collect insights and shape the product or its features.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-04"></span></div>
          <h4 class="turq-title-3">Strategize</h4>
          <p class="turq-description">Create frameworks that can extend to all target users.</p>
        </div>
      </div>
			<div class="row bprod mx-0 px-0 pos-bottom">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-05"></span></div>
          <h4 class="turq-title-3">Ideate</h4>
          <p class="turq-description">IIdeate to solve the problems focussing on the user.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-06"></span></div>
          <h4 class="turq-title-3">Wireframe</h4>
          <p class="turq-description">Transfer your ideas into connected workflows.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-07"></span></div>
          <h4 class="turq-title-3">Test & validate</h4>
          <p class="turq-description">Verify solutions with potential users.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon od-08"></span></div>
          <h4 class="turq-title-3">Launch</h4>
          <p class="turq-description">Work with developers to maintain design integrity.</p>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-blue-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue.svg')}/>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-central-data">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h1>
          Optimizing for effective results
          </h1>
          <p>
          Vizonomy could help you find out which issues to prioritize in order to enhance your product or service and amaze your users. Below, you’ll find where we focus as we prepare your product before any software development is started.
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Dealing with a complex user interface</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Clearing up the messaging system</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Optimizing the searching functionality</span></span>
							<span className="chk"><span className="ic-chk"></span><span className="tit">Following industry standards for UI design</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Perfecting the mobile experience</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Optimizing database architecture</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Simplifying the registration process</span></span>
            </div>
            <div className="block-c">
            </div>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/ux/bk-img-ux-02.png')}/>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="rivet-04">
    <img className="picture" src={require('../../images/ux/bk-rivet-ux-04.svg')}/>
  </div>
	<div className="sec-styling">
    <div className="container px-0 top-area">
      <div className="row">
        <div className="col-12">
          <h4 className="sm-title">User Experience</h4>
          <h1 className="md-title">We use industry-leading tools to create tangible yet creative results for our clients</h1>
        </div>
      </div>
    </div>
    <div className="container px-0 chk-area">
      <div className="row">
        <div className="col-3">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Adobe CS</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Sketch</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Framer</span></span>
          </div>
        </div>
        <div className="col-3">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Cinema 4D</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Invision App</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">After Effects</span></span>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
		<div className="bk-img-2">
			<img className="shape-mask" src={require('../../images/ux/bk-img-ux-08.png')}  alt="" />
		</div>
    <div className="bk-img-shadow">
			<img className="picture" src={require('../../images/ux/bk-img-ux-10.png')}  alt="" />
		</div>
  </div>
  <div className="sec-recent-work-2">
    <div className="centralize-data">
      <div className="col-a">
          <h4>User Experience</h4>
          <h1>Design sprint process</h1>
          <p>
          A Product Design Sprint is a multi-day workshop which uses design thinking to reduce the risks inherent in successfully bringing products to market. This planning methodology can be effective in moving your company forward, regardless the stage of your business. Each day of the sprint corresponds to another stage of solving the problem. Our flow has proved to be useful in finding the right solutions, building new features, and achieving goals. Explore different perspectives and identify viable solutions.
          </p>
      </div>
      <div className="col-b"></div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/ux/bk-img-ux-03.png')}  alt="" />
      </div>
    </div>
    <div className="area-chk">
      <div className="row">
        <div className="col-6">
					<p className="bold">
					After the Design Sprint, our team will send you a detailed report from the workshops with all materials and canvases digitized including:
        	</p>
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Lo-fi prototypes for some of the user stories</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">A project roadmap, broken down into phases</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Pre-defined tasks for each phase,</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">User journey maps, storyboards, and information architecture diagrams</span></span>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
	<div className="sec-white-riivet">
    <img className="picture" src={require('../../images/ux/bk-rivet-white.svg')}/>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-recent-work-3">
    <div className="fingertips">
      <div className="row">
        <div className="col-12">
          <div className="col-a">
            <h4>User Experience</h4>
            <h1>Project Management Approach</h1>
            <p>
            Product managers, designers, and engineers work together to extract requirements, prototype, test with users, and finally ship the product to market using an Agile approach - centered around the idea of iterative development, where requirements and solutions evolve throughout the product's lifecycle.
            </p>
          </div>
          <div className="col-b">
          <img className="picture" src={require('../../images/ux/bk-img-ux-04.png')}  alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-blue-2-rivet-bottom">
    <img className="picture" src={require('../../images/ux/bk-rivet-03-4-01.svg')}/>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-shaping-vision">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h4>User Experience</h4>
          <h1>
          Shaping a vision, delivering software, creating world class experiences
          </h1>
          <p>
          We partner with forward thinking agencies and brands globally to create connected design experiences. Those include delivering products centered on data visualization, geospatial analysis, mobile and web, and augmented reality. 
          </p>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-browsing-in-detail">
    <div className="navigation-area">
      <div className="list-items">
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/ux/bk-img-ux-05.png')}/>
            </div>
            <div className="description">
              <a href="/data-visualization"><h4>Data Visualization</h4></a>
              <p>Offer beautiful visualizations from the most commonplace to the most unique. Simplify complexity with custom dashboards.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/ux/bk-img-ux-06.png')}/>
            </div>
            <div className="description">
              <a href="/gis-web-mapping"><h4>GIS Mapping</h4></a>
              <p>Develop fluid and immersive mapping experiences with robust data pipelines across APIs, databases, and more.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img src={require('../../images/ux/bk-img-ux-07.png')}/>
            </div>
            <div className="description">
              <a href="/mobile-web"><h4>Mobile and Web</h4></a>
              <p>Responsive design leveraging the most modern web frameworks from React, Node, and Bootstrap.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img src={require('../../images/ux/im-ar.png')}/>
            </div>
            <div className="description">
              <a href="/augmented-reality"><h4>Augmented Reality</h4></a>
              <p>Present your data through new experiences. Overlay the digital world over the physical world.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
	<div className="sec-fdf">
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Functional Design First
            </h2>
            <p className="purple-description pad-bottom">
            Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
            </p>
            <div className="op-link op1">
              <a href="/natural-resources" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="link-sky-blue"><span className="op-link-title">Contact Us</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-1"></span></span>
            <h4 className="title-5">Design something new</h4>
            <p className="purple-description">
            <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-2"></span></span>
            <h4 className="title-5">Maintain existing system</h4>
            <p className="purple-description">
            We’ll <span className="light">review and refactor</span> your code for future improvements.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
	<div className="rivet-touch">
	  <img className="picture" src={require('../../images/analytics/rivet-lines-sky-blue.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>	
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="/drone-satellite">Drone and Satellite</a>
              <a href="/3d-visualization">3D Visualization</a>
              <a href="/real-time-analytics">Real-Time Analytics</a>
              <a href="/machine-learning">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="/drone-satellite">Drone and Satellite</a>
              <a href="/3d-visualization">3D Visualization</a>
              <a href="/real-time-analytics">Real-Time Analytics</a>
              <a href="/machine-learning">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
</div>
);
}
export default UserExperience;