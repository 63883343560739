/* import React from 'react'; */
import React, {useEffect } from 'react';


function TDVisualization (props) {
  useEffect(()=>{
    document.title = "3D Visualization | Vizonomy";
  },[]);
return (
<div className="TDVisualization">

</div>
);
}
export default TDVisualization;