export const dataDot = [
  {name: 'I', id: 1, data: [{value: 6, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 12, status: 'Completed'}, {value: 32, status: 'Completed'}, {value: 6, status: 'Completed'}, {value: 15, status: 'Active'}, {value: 10, status: 'Not Started'}, {value: 9, status: 'Not Started'}, {value: 1, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 6, status: 'Not Started'}]},
  {name: 'K', id: 1, data: [{value: 12, status: 'Completed'}, {value: 12, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 2, status: 'Completed'}, {value: 3, status: 'Completed'}, {value: 5, status: 'Active'}, {value: 16, status: 'Active'}, {value: 11, status: 'Not Started'}, {value: 3, status: 'Not Started'}, {value: 14, status: 'Not Started'}, {value: 11, status: 'Not Started'}]},
  {name: 'C', id: 1, data: [{value: 3, status: 'Completed'}, {value: 11, status: 'Completed'}, {value: 2, status: 'Completed'}, {value: 10, status: 'Completed'}, {value: 4, status: 'Completed'}, {value: 7, status: 'Active'}, {value: 6, status: 'Active'}, {value: 3, status: 'Not Started'}, {value: 4, status: 'Not Started'}, {value: 17, status: 'Not Started'}, {value: 4, status: 'Not Started'}]},
  {name: 'S', id: 1, data: [{value: 5, status: 'Completed'}, {value: 9, status: 'Completed'}, {value: 12, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 10, status: 'Active'}, {value: 12, status: 'Not Started'}, {value: 23, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 5, status: 'Not Started'}, {value: 5, status: 'Not Started'}, {value: 9, status: 'Not Started'}]},
  {name: 'L', id: 1, data: [{value: 6, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 11, status: 'Completed'}, {value: 4, status: 'Completed'}, {value: 12, status: 'Active'}, {value: 13, status: 'Not Started'}, {value: 13, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 6, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 10, status: 'Not Started'}]},
  {name: 'M', id: 1, data: [{value: 2, status: 'Completed'}, {value: 1, status: 'Completed'}, {value: 5, status: 'Completed'}, {value: 7, status: 'Completed'}, {value: 34, status: 'Active'}, {value: 8, status: 'Not Started'}, {value: 10, status: 'Not Started'}, {value: 6, status: 'Not Started'}, {value: 4, status: 'Not Started'}, {value: 7, status: 'Not Started'}, {value: 8, status: 'Not Started'}]},
  {name: 'G', id: 1, data: [{value: 1, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 3, status: 'Completed'}, {value: 1, status: 'Completed'}, {value: 65, status: 'Active'}, {value: 32, status: 'Not Started'}, {value: 12, status: 'Not Started'}, {value: 16, status: 'Not Started'}, {value: 10, status: 'Not Started'}, {value: 10, status: 'Not Started'}, {value: 3, status: 'Not Started'}]},
  {name: 'V', id: 1, data: [{value: 33, status: 'Completed'}, {value: 3, status: 'Completed'}, {value: 12, status: 'Completed'}, {value: 11, status: 'Completed'}, {value: 23, status: 'Completed'}, {value: 12, status: 'Active'}, {value: 2, status: 'Not Started'}, {value: 13, status: 'Not Started'}, {value: 12, status: 'Not Started'}, {value: 9, status: 'Not Started'}, {value: 22, status: 'Not Started'}]},
  {name: 'X', id: 1, data: [{value: 11, status: 'Completed'}, {value: 8, status: 'Completed'}, {value: 4, status: 'Completed'}, {value: 32, status: 'Completed'}, {value: 15, status: 'Active'}, {value: 8, status: 'Not Started'}, {value: 10, status: 'Not Started'}, {value: 11, status: 'Not Started'}, {value: 1, status: 'Not Started'}, {value: 13, status: 'Not Started'}, {value: 8, status: 'Not Started'}]},
  {name: 'A', id: 1, data: [{value: 8, status: 'Completed'}, {value: 10, status: 'Completed'}, {value: 10, status: 'Completed'}, {value: 12, status: 'Completed'}, {value: 1, status: 'Active'}, {value: 2, status: 'Delayed'}, {value: 12, status: 'Not Started'}, {value: 10, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 21, status: 'Not Started'}, {value: 7, status: 'Not Started'}]},
  {name: 'R', id: 1, data: [{value: 23, status: 'Completed'}, {value: 4, status: 'Completed'}, {value: 10, status: 'Completed'}, {value: 21, status: 'Completed'}, {value: 2, status: 'Delayed'}, {value: 5, status: 'Delayed'}, {value: 9, status: 'Not Started'}, {value: 9, status: 'Not Started'}, {value: 11, status: 'Not Started'}, {value: 2, status: 'Not Started'}, {value: 44, status: 'Not Started'}]}
];


export const colorScale = {
  'Completed': '#5E5FE2',
  'Active': '#047CD7',
  'Not Started': '#D4D2D9',
  'Delayed': '#F5575C'
};