/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function Agriculture (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'Agriculture';
  useEffect(()=>{
    document.title = "Agriculture | Vizonomy";
  },[]);
  // NELSON, en cada componente solo hay que cambiar la ruta de la imagen aqui 
  const imagesURLs = [
    'agriculture/bk-img-agriculture-02.png',
    'agriculture/bk-img-agriculture-03.png',
    'agriculture/bk-img-agriculture-04.png',
    'agriculture/bk-img-agriculture-05.png',
    'agriculture/bk-img-agriculture-06.png'
  ];
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="Agriculture">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Agriculture</span></h3>
          <h1 className="title-principal">Collect & integrate imagery, field notes, & real-time data</h1>
          <p className="description">
          Understand how to make the most of your limited resources at any time. Correlate NDVI, EVI and other vegetation indices with seasonal climate patterns to predict crop yield. Our tools enable you to carry your data on mobile devices to collaborate and make decisions faster.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/agriculture/bk-img-agriculture-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/agriculture/ic_brand-01.svg')} height="55%" width="55%"  alt="aker" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/agriculture/ic_brand-02.svg')} height="60%" width="60%"  alt="drone deploy" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/agriculture/ic_brand-03.svg')} height="60%" width="60%"  alt="sense fly" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/agriculture/ic_brand-04.svg')} height="55%" width="55%" alt="usgs" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    {/* <div className="bk-grid"></div> */}
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">Aker Agriculture</h1> 
      <p className="description">
      A custom project management platform that helps Chicago-based, Aker Ag, centralize operational, weather, and yield data for farming. Users can easily switch between fields, upload geo-referenced notes, and manage staffing schedules or raw material inventories.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Databases on GeoServer, MySQL and MongoDB. Graphics on Mapbox and D3.JS. Responsive web framework in Angular, Ant Design, and Bootstrap. Hosted on Amazon Web Services.
            </p>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Personalization</a>
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Events</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Map Explorer</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Note-taking</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Warehousing</a>
            </div>
            <div className="area-picture">
            <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Analysis</h4>
          <h1>Improve the efficiency of your production</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Health monitoring</h4>
          <p class="turq-description">Identify localized nutrient deficiencies and enable crop inputs with zone management.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Land planning</h4>
          <p class="turq-description">Schedule irrigation based on weather variables to avoid over-irrigation. With remote sensing, optimize where and when to water.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Disease mitigation</h4>
          <p class="turq-description">Run a multispectral analysis to detect subtle differences in your vegetation and enable localized treatment before it spreads.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Environmental mapping</h4>
          <p class="turq-description">Map natural resources to ensure regulatory compliance and manage risks from natural disasters. Classify areas with great detail to enable more efficient responses.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-blue-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Centralize your data
          </h1>
          <p>
          Vizonomy will work with your team to determine the best data architecture: whether it's synchronizing ESRI service layers into your map or creating new data tables in a cloud environment. We work with you to optimize the data structure so that we can achieve the highest performance. Data types we work with include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Media</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">PDFs</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Social Media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Shapefiles</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">GeoDatabases</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Imagery</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="/data-visualization" class="link-blue">
              <span class="op-link-title">Learn More</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/agriculture/bk-img-agriculture-02.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Satellite & Drone Imagery</h4>
          <h1>Assess vegetative health and correlate crop yield</h1>
          <p>
          Vizonomy develops the processes, algorithms, and mapping applications to overlay drone imagery with your field notes and other GIS data. Correlate NDVI, EVI and other vegetation indices with seasonal climate patterns to predict crop yield. Our tools enable you to carry your data on mobile devices to collaborate and make decisions faster.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/agriculture/bk-img-bi-crop.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Custom content management systems (CMS) for uploading new datasets and editing documents</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="/gis-web-mapping" class="btn-blue">
              <span class="op-link-title">Learn more</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-blue-2-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue-2.svg')}/>
  </div>
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Agriculture;