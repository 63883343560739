/* import React from 'react'; */
import React, { Component,useState,useEffect } from 'react';
import Header from '../Header';
function Company (props) {
  const [solutionActive, setSolution] = useState(false);
  const [analyticsActive, setAnalytics] = useState(false);
  const [designActive, setDesign] = useState(false);
  const [companyActive, setCompany] = useState(false);
  useEffect(()=>{
    document.title = "Company | Vizonomy";
  },[]);
return (
<div className="Company">
  <div className="sec-a">
    <div className="bk-disposition">
    </div>
    <div className="bk-light-blue">
    </div>
    <div className="newviz-content">
      <div className="show-content">
        <div className="presentation">
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0 block-1">
              <h1 className="title-principal">
              About Vizonomy
              </h1>
              <p className="description">
              Vizonomy is a leading Design & Data Strategy firm for data science, web visualization and online mapping. Headquartered in Washington DC, we build custom tools that visualize environmental risk, monitor projects in real-time, and interpret drone and satellite imagery continuously.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="rivet-sec-a">
    <img className="picture" src={require('../../images/company/bk-bottom.svg')} alt="" />
  </div>
  <div className="sec-company-whoare">
    <div className="bk-image">
      <img src={require('../../images/company/ic_bk_whoare.png')} alt="" />
    </div>
    <div className="content-whoare">
      <div className="container">
        <div className="row block-1">
          <div className="col-6 block-1">
            <h2 className="purple-title-2">
            Who we are
            </h2>
            <p className="purple-description">
            We think that beautiful design is fundamental for interpreting your spatial data. Whether it is showcasing millions of points on a map or reliably connecting terabytes of data sources, we continually find new ways to help you understand your data.
            </p>
            <p className="purple-description">
            We love discovering how our maps, dashboards, and tools can drive new experiences: by helping cities assess their climate resilience, assisting organizations track their staffing needs, or allowing start-ups to conceptualize their ideas.
            </p>
            <p className="purple-description">
            Vizonomy also partners with amazing companies. With CARTO, we provide the most innovative spatial architecture for all your analysis and hosting. Share your challenge with our team, and we’ll work with you to deliver a revolutionary digital product.
            </p>
          </div>
          <div className="col-6 block-1 lastarea">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-shapingvision">
    <div className="container b-top">
      <h1 className="purple-title-2">Shaping a vision, delivering software, creating world class experiences</h1> 
      <p className="purple-description">
      Collaborating with Vizonomy can have a lasting impact on how your team approaches innovation.
Our   team has a strong focus on not just doing what clients ask for. We value certain core beliefs.
      </p>
    </div>
    <div className="container b-bottom">
      <div className="row block-2">
        <div className="col-8"><img className="picture" src={require('../../images/company/ic-shapingvis-01.png')} alt="logo" /></div>
        <div className="col-4">
          <h3>Ideation & Evaluation</h3>
          <p className="purple-description">
          Give your product idea a real shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate potential mistakes.
          </p>
        </div>
      </div>
      <div className="row block-2">
        <div className="col-8"><img className="picture" src={require('../../images/company/ic-shapingvis-02.png')}  alt="logo" /></div>
        <div className="col-4">
          <h3>Design Process</h3>
          <p className="purple-description">
          Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces.
          </p>
        </div>
      </div>
      <div className="row block-2">
        <div className="col-8"><img className="picture" src={require('../../images/company/ic-shapingvis-03.svg')}  alt="logo" /></div>
        <div className="col-4">
          <h3>Development Process</h3>
          <p className="purple-description">
          Create beautiful, fast, and secure applications tailored precisely to your business goals.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-brands">
    <div className="container">
      <div className="brands-top">
        <h1>
        From startups to the world's largest organizations
        </h1>
        <p>
        Over the last decade, we’ve helped our clients ship products for multiple platforms
        </p>
      </div>
      <div className="brands">
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-01.svg')} height="70%" alt="siemens" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-02.svg')} height="80%" alt="instacart" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-03.svg')} height="80%" alt="the world bank" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-04.svg')} height="80%" alt="gileal" /></div>
        </div>
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-09.svg')} height="80%" alt="undp" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-08.svg')} height="70%" alt="carto" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-10.svg')} height="80%" alt="national geographic" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-11.svg')} height="70%" alt="aecom" /></div>
        </div>
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-05.svg')} height="80%" alt="undp" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-06.svg')} height="80%" alt="carto" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-07.svg')} height="80%" alt="national geographic" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-08.svg')} height="60%" alt="aecom" /></div>
        </div>
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-12.svg')} height="80%" alt="undp" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-13.svg')} height="80%" alt="carto" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-14.svg')} height="70%" alt="national geographic" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/company/brand-15.svg')} height="70%" alt="aecom" /></div>
        </div>
      </div>
    </div>
  </div>
  <div className="offices">
    <div className="container">
      <div className="top">
        <h2>
        Vizonomy is headquartered in Washington DC, with offices in London and La Paz.
        </h2>
      </div>
      <div className="quantity">
        <div className="left">
          <div className="col-q">
            <span className="l-col-a">2015</span>
            <span className="l-col-b">Founded</span>
          </div>
          <div className="col-q">
            <span className="l-col-a">28+</span>
            <span className="l-col-b">Employees</span>
          </div>
          <div className="col-q">
            <span className="l-col-a">3</span>
            <span className="l-col-b">Offices</span>
          </div>
          <div className="col-q">
            <span className="l-col-a">+70</span>
            <span className="l-col-b">Projects</span>
          </div>
        </div>
        <div className="right">
        </div>
      </div>
    </div>
  </div>
  <div className="sec-people">
    <div className="bk-people">
      <img className="picture" src={require('../../images/company/ic_company-people-2.png')}/>
    </div>
  </div>
  <div className="sec-fdf">
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Functional Design First
            </h2>
            <p className="purple-description pad-bottom">
            Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
            </p>
            <div className="op-link op1">
              <a href="/gis-web-mapping" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="link-sky-blue"><span className="op-link-title">Contact Us</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-1"></span></span>
            <h4 className="title-5">Design something new</h4>
            <p className="purple-description">
            <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
            </p>
            <div className="op-link op2">
              <a href="/user-experience" className="link-sky-blue"><span className="op-link-title">Our Approach</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-2"></span></span>
            <h4 className="title-5">Maintain existing system</h4>
            <p className="purple-description">
            We’ll <span className="light">review and refactor</span> your code for future improvements.
            </p>
            <div className="op-link op2">
              <a href="/customers" className="link-sky-blue"><span className="op-link-title">Our Customers</span><span className="arrow-blue"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-fdf-rivet-bottom">
    <img className="picture" src={require('../../images/company/ic_company-rivet-2.svg')}/>
  </div>
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Company;