/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function BusinessIntelligence (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'BusinessIntelligence';
  useEffect(()=>{
    document.title = "Business Intelligence | Vizonomy";
  },[]);
  // NELSON, en cada componente solo hay que cambiar la ruta de la imagen aqui 
  const imagesURLs = [
    'bi/bk-img-bi-02.png',
    'bi/img-carousel-02.png',
    'bi/bk-img-bi-04.png',
    'bi/bk-img-bi-05.png',
    'bi/bk-img-bi-06.png'
  ];
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="BusinessIntelligence">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Business Intelligence</span></h3>
          <h1 className="title-principal">Custom and scalable solutions for real-time location analysis</h1>
          <p className="description">
          Make data-driven decisions about site selection, marketing, and product assortment. Smart maps and analytics through our custom solutions make it easier to find hidden patterns and trends in customer, sales, and market data.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/bi/bk-img-bi-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-01.svg')} height="50%" width="50%"  alt="aecom" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-02.svg')} height="70%" width="70%"  alt="carto" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-03.svg')} height="60%" width="60%"  alt="instant" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/bi/ic_brand-04.svg')} height="50%" width="50%" alt="ipg" /></div>
      </div>
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-05.svg')} height="83%" width="83%"  alt="li fung" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-06.svg')} height="60%" width="60%"  alt="siemens" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/bi/ic_brand-07.svg')} height="60%" width="60%"  alt="tomtom" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/bi/ic_brand-08.svg')} height="60%" width="60%" alt="worldaware" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    {/* <div className="bk-grid"></div> */}
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">Siemens</h1> 
      <p className="description">
      Vizonomy built an intuitive geospatial interface that empowers transit engineers from Siemens to perform vehicle capacity and occupancy analysis across train stations in Germany. Traffic data is visualized in real-time to detect anomalies or predict future passenger flow.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Databases on CARTO, MySQL and MongoDB. Graphics on Mapbox and D3.JS. Responsive web framework in Angular, Ant Design, and Bootstrap. Hosted on Amazon Web Services.
            </p>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Custom Styling</a>
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Login Interface</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Unique Graphics</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Relational Analysis</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Temporal Filtering</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your production</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Get the most out of your data</h4>
          <p class="turq-description">Whether you're a retailer, a multi-national, or any other small business, through data-guided insights you can find your next location, track your supply chain, understand your customer, and bring your story to a whole new level.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Interactive dashboards</h4>
          <p class="turq-description">How can you better meet your customers’ needs? Is there a way to make better decisions that increase your profitability? Through interactive, web dashboards gain sights by a clicking, pointing, zooming, and more.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Mapping & spatial analytics</h4>
          <p class="turq-description">We build custom tools based on your needs. Determine population statistics around your location, manage human resources with real-time data, and track your shipments across any device.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Decisions with real-time data</h4>
          <p class="turq-description">Incorporate up-to-the-minute data on customer and vehicle locations, assets, sales, and environments into maps and analysis. Include anonymized credit card data, foot traffic, or other APIs.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-blue-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Digitizing mobile data collection
          </h1>
          <p>
          Working with a digital ad agency in Washington DC, Vizonomy developed a modern survey builder accessible across any device. Capturing rich geospatial data, pictures, video, and more, the survey builder has saved our client hundreds of hours and allowed for better data-driven decision making. Key features include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">No-code surveys</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Share via url</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Responsive design</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Capture spatial data</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Upload media files</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Store data offline</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="/data-visualization" class="link-blue">
              {/* <span class="op-link-title">See similar example</span>
              <span class="arrow-blue"></span> */}
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/bi/ic_BI7.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>IPG Solution for Billboard Site Selection</h1>
          <p>
          An on-premise solution that centralizes demographic, billboard location, and traffic data for return on investment analysis. With more than 20,000 billboards across the United States, Vizonomy's solution allows for a quick evaluation of which locations are underperforming, are overvalued, or are ideal for growth.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/bi/bk-img-bi-03.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Billboard revenue and sales history</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Content management system for editing datasets</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Temporal analytics via timeline</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Filtering by attribute e.g. vendor, format, size, and market</span></span>
          </div>
          <div class="op-link">
            <a href="http://vizonomy.com/gis-web-mapping" class="btn-blue">
              <span class="op-link-title">Learn more</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-blue-2-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue-2.svg')}/>
  </div>
  <div className="sec-features">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h3>Case Study</h3>
          <h1>WorldAware</h1> 
          <p className="description">
          Vizonomy built an email-based notification system for WorldAware and Allianz, warning clients of any significant travel, safety and security-related incidents. Getting timely notification of such issues or incidents, with applied analysis, gives the organization the advantage to avoid the risk and plan around it.
          </p>
          <div class="op-link">
            <a href="https://confluence.mhfd.org/login" class="link-blue">
              <span class="op-link-title">See live sites</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="row list-systems">
        <div className="col-4">
          <div className="area-img">
            <img className="picture" src={require('../../images/bi/img-wa-01.png')}/>
          </div>
          <h3>
          Custom email notifications
          </h3>
          <p>
          A sleek, personalized email showing the recipient's affected assets and the corresponding security-related event through an interactive map and dynamic table.
          </p>
          <div class="op-link">
            <a href="/gis-web-mapping" class="link-blue">
              <span class="op-link-title">GIS & web mapping</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/bi/img-wa-02.png')}/>
          </div>
          <h3>
          Report generator
          </h3>
          <p>
          A PDF report of all affected assets for a specific client.
          </p>
          <div class="op-link">
            <a href="/mobile-web" class="link-blue">
              <span class="op-link-title">Mobile and web</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/bi/img-wa-03.png')}/>
          </div>
          <h3>
          Editable spatial rules
          </h3>
          <p>
          Email notifications are sent by security type and triggers enabled by proximity or exposed dollar value.
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">Real-time analytics</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/bi/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/bi/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/bi/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default BusinessIntelligence;