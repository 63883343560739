/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function Climate (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'Climate';
  useEffect(()=>{
    document.title = "Climate | Vizonomy";
  },[]);
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="Climate">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Climate</span></h3>
          <h1 className="title-principal">Assess flood risk with real-time data and dynamic mapping</h1>
          <p className="description">
          Support your hazard mitigation plan with an immersive, digital experience. Assess vulnerable populations based on age, race, family income, and other key attributes. Composit these layers and an economic loss model for internal analysis or public awareness.
          </p>
          <div class="op-link">
            <a href="http://dcfloodrisk.org/main" class="btn-blue">
              <span class="op-link-title">Live Example</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/climate/bk-img-climate-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-01.svg')} height="70%" width="70%"  alt="four twenty seven" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-02.svg')} height="50%" width="50%"  alt="aecom" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-03.svg')} height="70%" width="70%"  alt="athenium analytics" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/climate/ic_brand-04.svg')} height="60%" width="60%" alt="broward" /></div>
      </div>
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-05.svg')} height="60%" width="60%"  alt="chemonics" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-06.svg')} height="60%" width="60%"  alt="department of energy & enviroment" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/climate/ic_brand-07.svg')} height="60%" width="60%"  alt="fremont" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/climate/ic_brand-08.svg')} height="60%" width="60%" alt="resurgence" /></div>
      </div>
    </div>
  </div>
  <div className="sec-features">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>Climate Risk Planning 2.0</h1> 
          <p className="description">
          What is the FEMA flood zone for a building? What is the 100YR flood loss for a coastal home? How many miles of roadway are exposed to a sea level rise scenario? Answer these questions via Vizonomy's climate risk solutions.
          </p>
          <div class="op-link">
            <a href="http://dcfloodrisk.org/main" class="link-blue">
              <span class="op-link-title">View Live Project</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="row list-systems">
        <div className="col-4">
          <div className="area-img">
            <img className="picture" src={require('../../images/climate/im-cr-01.png')}/>
          </div>
          <h3>
          Import Data
          </h3>
          <p>
          Stream data from government data portals or import your local SHP, CSV, GeoJSON, and KMLs. Our comprehensive library easily allows users to analyze data from FEMA, NOAA, DOT, DHS, DOE, and other agencies; and OpenStreet Map.
          </p>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/climate/im-cr-02.png')}/>
          </div>
          <h3>
          Run What-If Scenarios
          </h3>
          <p>
          How many buildings within a given Census Block, Neighborhood, or Municipality are exposed to the 100YR, 500YR, 1-6FT sea level rise scenarios? What comprises the vulnerable population within a custom flood scenario?
          </p>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/climate/im-cr-03.png')}/>
          </div>
          <h3>
          Share Insights
          </h3>
          <p>
          Share results via PDF or CSV. Also, use your insights to build public support for needed mitigation measures. See our work with Washington DC’s Department of Energy & the Environment.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Climate Platform</h4>
          <h1>ASTERRA Flood Risk Terminal</h1>
          <p>
          A cloud-based solution designed for planners, emergency managers, transportation specialists and sustainability officers. Search +70 layers, import your local data, analyze economic losses by flood scenarios, develop real-time weather alerts and create dynamic hazard risk assessments for your community. Designed for planning, transportation, emergency response, and sustainability agencies.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/climate/bk-img-climate-02.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Rely on more than 700 depth-damage methods for estimating direct economic losses by structure, neighborhood, or city</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Receive trigger alerts based on upcoming weather events for immediate planning and emergency response.</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Apply data crawlers on social media for real-time, crowdsourcing intelligence.</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Improve your Community Rating System and reduce insurance premiums for your jurisdiction with a public portal.</span></span>
          </div>
          <div class="op-link">
            <a href="/contact-us" class="btn-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-blue">
    <img className="picture" src={require('../../images/climate/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Case Study: Washington DC (DOEE)
          </h1>
          <p>
          Updated in 2020, the District's new Flood Risk Portal features a mobile-friendly and refreshed design that easily communicates flood and sea level rise risk to the public. The portal includes assets across transportation, finance, government, commercial, water, communication, education, flood protection and health sectors. It will soon enable analysis across vulnerable populations based on age, race, family income, and other key attributes. Key features include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Assess 100yr losses by building</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Assess exposure by polygon</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Mine social media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Export results to PDF</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Mobile-friendly design</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Crowdsource flood reports (soon)</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="http://dcfloodrisk.org/main" class="link-blue">
              <span class="op-link-title">View Live Project</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/climate/bk-img-climate-03.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-custom-solution">
    <div className="container px-0">
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
          <h4>Custom Solutions</h4>
          <h1>The World Bank - Colombo, Sri Lanka</h1>
          <p>
          A geospatial a visualization solution that centralizes +20GB of documents, excel files, and spatial information related to flood risk in Sri Lanka. Economic losses, property and demographic exposure, and other custom analyses can be performed across the country's 12 major basins. As a result, the platform is helping the government direct investment toward effective flood mitigation and disaster avoidance. Key features include:
          </p>
        </div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/climate/bk-img-climate-04.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Polygon-based analyses and flood loss calculations</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Content management system for uploading new datasets and editing documents</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">View Live Project</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/climate/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/climate/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/climate/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Climate;