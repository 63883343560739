import React, { Component, useState,  useEffect } from 'react';

function Main () {
  const [solutionActive, setSolution] = useState(false);
  const [analyticsActive, setAnalytics] = useState(false);
  const [designActive, setDesign] = useState(false);
  const [companyActive, setCompany] = useState(false);
  useEffect(()=>{
    document.title = "Home | Vizonomy";
  },[]);
return (
<div className="Main">
  <div className="sec-a">
    <div className="bk-disposition">
    </div>
{/*     <div className="main-top-bk">
      <video id="vp" autoPlay muted loop preload>
        <source src={require('../../images/main/movie-03.mp4')}/>
      </video>
      <svg id="layer-1" data-name="layer-1" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
        <defs>
          <mask id="mask" x="0" y="0" height="100%" width="100%">
            <rect x="0" y="0" height="100%" width="100%"></rect>
            <path class="cls-1" d="M0,0V510S1810.54,201.67,1920,187.88V0Z"/>
          </mask>
        </defs>
        <rect x="0" y="0" height="100%" width="100%"></rect>
      </svg>
      <svg id="layer-2" data-name="layer-2" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
        <defs>
          <mask id="mask-2" x="0" y="0" height="100%" width="100%">
            <rect x="0" y="0" height="100%" width="100%"></rect>
            <path class="cls-1" d="M0,0V283s198-21,375-69V0Z"/>
          </mask>
        </defs>
        <rect x="0" y="0" height="100%" width="100%"></rect>
      </svg>
    </div> */}
    <div className="main-top-vid">
        <video id="shape-mask" autoPlay muted loop preload>
            <source src={require('../../images/main/movie-03.mp4')}/>
        </video>
    </div>
    <div className="bk-small-device">
      <img className="picture" src={require('../../images/main/bk-gradient.svg')} />
    </div>
    <div className="newviz-content">
      <div className="show-content">
      <div className="presentation">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h1 className="title-principal">
              <span className="light">Beautiful Data for Insightful</span> Analysis
            </h1>
            <p className="description">
            Vizonomy creates products, services and platforms for a connected world, grounded in state-of-the-art data science and visualization. We build custom tools that visualize environmental risk, monitor projects in real-time, interpret satellite imagery and more.
            </p>
            <div className="op-link">
              <a href="/gis-web-mapping" className="btn-blue-dark"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="btn-white"><span className="op-link-title">Contact Us</span><span className="arrow-dark"></span></a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
          </div>
          <div className="col-36mx-0 px-0 block-2">
          </div>
        </div>
      </div>
      <div className="brands">
        <div className="row block-b">
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-01.svg')} height="70%" alt="siemens" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-02.svg')} height="80%" alt="instacart" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-03.svg')} height="80%" alt="the world bank" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-04.svg')} height="80%" alt="gileal" /></div>
        </div>
        <div className="row block-b">
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-05.svg')} height="80%" alt="undp" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-06.svg')} height="80%" alt="carto" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-07.svg')} height="80%" alt="national geographic" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/main/brand-08.svg')} height="70%" alt="aecom" /></div>
        </div>
      </div>
      <div className="bk-theme-2">
        <div className="right-side">
            <div className="show-part">
                <img className="picture" src={require('../../images/main/bk-cover-2.png')} alt="" />
            </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div className="sec-b">
    <div className="bk-disposition">
    <div className="newviz-content-2">
      <div className="presentation-2">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <h1 className="purple-title">
            Location Intelligence 
            </h1>
            <h2 className="purple-title-2">
            Customized spatial and data visualization solutions
            </h2>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-2">
              <p className="purple-description">
              From tools and algorithms that collect mobile data to dynamic dashboards that provide critical insight, we provide custom solutions most appropriate for you. We marry <span className="light">intentional design with a proven infrastructure that will support any type of audience and ensure high performance.</span> Our reliance on open source components will ensure that we build the most cost-effective, beautiful, and informative solution for you.
              </p>
            </div>
            <div className="col-6 mx-0 px-0 block-2">
              <p className="purple-description">
              Whether it’s displaying tens of millions of cargo ship positions to find routing patterns or calculating economic risk from flood scenarios across any city, <span className="light">we are prepared to develop scalable and high-performance solutions for any industry.</span>
              </p>
            </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-2">
            <div className="op-link">
              <a href="/gis-web-mapping" className="btn-blue-dark"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div className="bk-desktops">
      <img className="picture" src={require('../../images/main/bk-cover-3.png')}/>
    </div>
    </div>
  </div>
  <div className="rivet-b">
    <img className="picture" src={require('../../images/main/bk-blue-dark-foot.svg')}  alt="logo" />
  </div>
  <div className="sec-c">
    <div className="bk-disposition">
      <div className="newviz-content-3">
        <div className="presentation-3">
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0 block-1">
              <h1 className="turq-title">
              Custom Tools + Dashboards
              </h1>
              <h2 className="turq-title-2">
              Immersive platforms, fluid maps, and dynamic graphics
              </h2>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-2">
              <p className="turq-description">
              All our designs are intentional. From our modern interfaces to the robust architectures underpinning our tools, we craft each component specific for your needs.
              </p>
            </div>
            <div className="col-6 mx-0 px-0 block-2">
              <p className="turq-description">
              </p>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0 block-2">
              <div className="op-link">
                <a href="/gis-web-mapping" className="btn-turquoise"><span className="op-link-title">Our Work</span><span className="arrow-dark"></span></a>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-0 block-b">
            <div className="col-3 mx-0 px-0 block-2">
              <span className="icon"><span className="ic-turq-1"></span></span>
              <h2 className="turq-title-3">
              Open source stack
              </h2>
              <p className="turq-description">
              We offer client and server libraries in everything from React and PHP to MongoDB and iOS.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <span className="icon"><span className="ic-turq-2"></span></span>
              <h2 className="turq-title-3">
              Other integrations
              </h2>
              <p className="turq-description">
              Integrate your custom solution with world-class technologies like MailChimp, Mapbox, CARTO, Google Authentication, and more.
              </p>
            </div>
            <div className="col-6">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-hot-map">
      <img className="picture" src={require('../../images/main/bk-cover-5.png')}/>
    </div>
  </div>
  <div className="bk-hot-map-mob">
    <div className="row mx-0 px-0 bloq-c">
        <div className="col-12">
          <img className="picture" src={require('../../images/main/bk-cover-5.png')}/>
        </div>
    </div>
  </div>
  <div className="rivet-c">
    <img className="picture" src={require('../../images/main/bk-cover-10.svg')}  alt="logo" />
  </div>
  <div className="sec-d">
    <div className="bk-disposition">
      <div className="newviz-content-4">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <h1 className="purple-title">
            What We Do
            </h1>
            <h2 className="purple-title-2">
            Storytelling and analytics through modern technology
            </h2>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-wwd-1"></span></span>
            <h4 className="title-5">Responsive Web Design</h4>
            <p className="purple-description">
            Showcase the best of your organization. Reimagine your digital presence through a <span className="light">consistent user experience</span> across mobile and desktop devices.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-wwd-2"></span></span>
            <h4 className="title-5">Data Visualization</h4>
            <p className="purple-description">
            Sleek, dynamic graphics ranging from the most common to the most extreme. We build <span className="light">data pipelines, uploaders, and other tools</span> for integrating your data seamlessly.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-wwd-3"></span></span>
            <h4 className="title-5">Geospatial</h4>
            <p className="purple-description">
            <span className="light">Immersive maps</span> that bring your data to life. Forget that 90s look, we're all about style and high performance.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-wwd-4"></span></span>
            <h4 className="title-5">Aerial Analysis</h4>
            <p className="purple-description">
            Complete your due diligence, augment your data, and <span className="light">gain the insight</span> you need to reduce costs, increase opportunities, and confirm asset intelligence.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="rivet-globe">
    <img className="picture" src={require('../../images/main/rivet-globe-1.svg')}/>
  </div>
  <div className="rivet-globe-small">
    <img className="picture" src={require('../../images/main/rivet-globe-small-1.svg')}/>
  </div>
  <div className="sec-discover-insights">
    <div className="bk-image">
    </div>
    <div className="float-canvas">
      <div className="bk-disposition">
        <div className="newviz-content-5 view-s">
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0 block-1">
              <h1 className="turq-title">
              Discover Insights
              </h1>
              <h2 className="turq-title-2">
              View patterns at any scale
              </h2>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-2">
              <p className="turq-description pad-bottom">
              Our clients have data structures of all kinds: from uncleaned CSVs and messy shapefiles to complex web mapping services, APIs and more. We normalize your data, store it on-premise or in the cloud, and deliver it to your global users efficiently. We do this through a combination of open source tools such as <span className="light">MongoDB, MySQL Node.JS, and others.</span>
              </p>
            </div>
            <div className="col-6 mx-0 px-0 block-2">
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Identify relationships over time</h4>
              <p className="turq-description">
              Uncover causal effects, growth patterns and hiearchy.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Develop spatial trigger events</h4>
              <p className="turq-description">
              Send emails or messages based on spatial rules.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Monitor projects in real time</h4>
              <p className="turq-description">
              Upload media, View status changes, and Manage staffing.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Data mine social media</h4>
              <p className="turq-description">
              Query by keyword across Twitter, Facebook or Snap.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="video-area">
      <div className="show-d">
        <div className="main-top-bk-3">
           <video className="shape-mask" autoPlay muted loop preload >
               <source src={require('../../images/main/movie-02.mp4')} type="video/mp4"/>
           </video>
        </div>
      </div>
    </div>  
  </div>
  <div className="sec-fun">
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Functional Design First
            </h2>
            <p className="purple-description pad-bottom">
            Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
            </p>
            <div className="op-link">
              <a href="/our-approach" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="link-purple"><span className="op-link-title">Contact Us</span><span className="arrow-purple"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-1"></span></span>
            <h4 className="title-5">Responsive Web Design</h4>
            <p className="purple-description">
            <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2 block-b">
            <span className="icon-wwd"><span className="ic-df-2"></span></span>
            <h4 className="title-5">Data Visualization</h4>
            <p className="purple-description">
            We’ll <span className="light">review and refactor</span> your code for future improvements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="rivet-f">
    <img className="picture" src={require('../../images/main/bk-cover-11.svg')}  alt="logo" />
  </div>
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

);
  
}

export default Main;
