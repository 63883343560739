/* import React from 'react'; */
import React, { useEffect } from 'react';

function DataVisualization (props) {
  useEffect(()=>{
    document.title = "Data Visualization | Vizonomy";
  },[]);
return (
<div className="DataVisualization">
  <div className="sec-nr">
    <div className="bk-grid">
    </div>
    <div className="content-nr">
	  	  <div className="navi">
          <div className="side-a">
            <h3><span className="ic-gw"></span><span className="title">Data Visualization</span></h3>
          </div>
          <div className="side-b">
          <a href="/user-experience">User Experience</a>
          <a href="/gis-web-mapping">GIS & Web Mapping</a>
          <a href="/data-visualization" className="active">Data Visualization</a>
          <a href="/mobile-web">Mobile & Web</a>
          <a href="/augmented-reality">Augmented Reality</a>
          </div>
        </div>
        <div className="row mx-0 my-0 cover-detail">
          <div className="col-9">
            <h1 className="title-principal">Immersive data visualization for insightful analysis</h1>
            <p className="description">
            In the world of big data, data visualization tools and technologies are essential to analyzing massive amounts of information and making data-driven decisions. 
            </p>
            <div class="op-link">
              <a href="https://vizonomy.com/natural-resources" class="btn-blue">
                <span class="op-link-title">Projects</span><span class="arrow-white"></span>
              </a>
              <a href="/contact-us" class="link-blue">
                <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-4">
          </div>
        </div>
    </div>
{/* <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/dv/bk-img-dv-01.png')} />
      </div>
    </div> */}
    <div className="slider-area">
      <div class="tech-slideshow">
        <div class="mover-1"></div>
        {/* <div class="mover-2"></div> */}
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
  </div>
  <div className="sec-usecase-1">
    <div className="bk-grid"></div>
    <div className="touch-friendly">
      <div class="row bprod mx-0 px-0">
        <div class="col-6 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon odd-01"></span></div>
          <h4 class="turq-title-3">Lightweight Source Code</h4>
          <p class="turq-description">Using visualization libraries such as D3.JS, Highcharts and Google charts, we ensure minimal load-times and responsive interactions.</p>
        </div>
        <div class="col-6 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon odd-02"></span></div>
          <h4 class="turq-title-3">Mobile or Touch-Friendly</h4>
          <p class="turq-description">We design the most effective strategy to ensure your message is available across all devices. This includes replacing graphics and/or alternating its representation.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-recent-work-3">
    <div className="rw-top">
        <div className="row">
          <div className="col-12">
            <h4>Data Visualization</h4>
            <h1>The world of visualization at your fingertips</h1>
            <p>
            Our design team will work with you to develop the database infrastructure and chart type that best suits your audience's needs. Whether we stream your data synchronously via API or store it in Postgres, MongoDB, or other location, we'll ensure high performance for the most demanding analysis.
            </p>
          </div>
        </div>
    </div>
    <div className="fingertips">
      <div className="row">
        <div className="col-12">
          <div className="col-a">
            <h1 className="blue">Let your data communicate a compelling story</h1>
            <p>
            Our solutions integrate mapping and graphics seamlessly for a multi-point-of-view analysis. Whether it is viewing train occupancy levels for Siemens or KPIs for the World Bank, our dashboards provide the unique insights needed that big box solutions cannot meet.
            </p>
          </div>
          <div className="col-b">
            <img className="picture" src={require('../../images/dv/bk-img-dv-02.png')}  alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="rivet-01">
    <img className="picture" src={require('../../images/dv/bk-rivet-01.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-recent-work-4">
    <div className="fingertips">
      <div className="row">
        <div className="col-12">
          <div className="col-a">
            <h1 className="blue">Data visualization through open source</h1>
            <p>
            All of our geoplatforms, dashboards, data portals, and web tools include some type of visualization. Depending on your needs, we can embrace either open source tools - such as D3.JS, R Studio, or Python - or proprietary platforms such as Tableau. Either way, expect beautifully-designed graphics that can help with your decision making.
            </p>
          </div>
          <div className="col-b">
            <img className="picture" src={require('../../images/dv/bk-img-dv-03.png')}  alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="rivet-02">
    <img className="picture" src={require('../../images/dv/bk-rivet-02.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-styling">
    <div className="common-requests">
      <div className="row">
        <div className="col-12">
          <div className="col-a">
            <h4 className="sm-title">Data Visualization</h4>
            <h1 className="md-title">Most common requests by our clients</h1>
            <p>
            We design our dashboards with the intent to give your audience a powerful tool to drill down, highlight, and filter your data on the fly. Include maps. Connect to your internal systems. Or collect data from the crowd. Everything is possible.
            </p>
            <div className="group-chk">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Customize pop-ups for each graphic and map feature</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Filter maps by graphic selection or attribute filter</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Connect to datasets in real-time</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Change colors, title positions, axes, padding etc</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Develop chart types using your brand's unique style</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Enable mobile-friendly designs </span></span>
            </div>
            <div class="op-link">
              <a href="/contact-us" class="btn-blue">
                <span class="op-link-title">Contact Us</span><span class="arrow-white"></span>
              </a>
            </div>
          </div>
          <div className="col-b">
            <img className="picture" src={require('../../images/dv/bk-img-dv-04.png')}  alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="rivet-03">
    <img className="picture" src={require('../../images/dv/bk-rivet-03.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-central-data">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h1>
          Centralize your data
          </h1>
          <p>
          Vizonomy will work with your team to determine the best data architecture: whether it's synchronizing ESRI service layers into your map or creating new data tables in a cloud environment. We work with you to optimize the data structure so that we can achieve the highest performance. Data types we work with include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Media</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">PDFs</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Social Media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Shapefiles</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">GeoDatabases</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Imagery</span></span>
            </div>
            <div className="block-c">
            </div>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/dv/bk-img-dv-05.png')}/>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="rivet-04">
    <img className="picture" src={require('../../images/dv/bk-rivet-04.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-shaping-vision">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h4>Data Visualization</h4>
          <h1>
          Shaping a vision, delivering software, creating world class experiences
          </h1>
          <p>
          We partner with forward thinking agencies and brands globally to create connected design experiences. Those include delivering products centered on data visualization, geospatial analysis, mobile and web, and augmented reality. 
          </p>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-browsing-in-detail">
    <div className="navigation-area">
      <div className="list-items">
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/dv/bk-img-dv-09.png')}/>
            </div>
            <div className="description">
              <a href="/user-experience"><h4>User Experience</h4></a>
              <p>Craft user roles & workflows with our dedicated design sprint team. Empathize with your audience & be strategic in your design decisions.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/dv/bk-img-dv-07.png')}/>
            </div>
            <div className="description">
              <a href="/mobile-web"><h4>Mobile and Web</h4></a>
              <p>Responsive design leveraging the most modern web frameworks from React, Node, and Bootstrap.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img src={require('../../images/dv/bk-img-dv-08.png')}/>
            </div>
            <div className="description">
              <a href="/augmented-reality"><h4>Augmented Reality</h4></a>
              <p>Present your data through new experiences. Overlay the digital world over the physical world.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/ux/bk-img-ux-06.png')}/>
            </div>
            <div className="description">
              <a href="/gis-web-mapping"><h4>GIS Mapping</h4></a>
              <p>Develop fluid and immersive mapping experiences with robust data pipelines across APIs, databases, and more.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
	<div className="sec-fdf">
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Functional Design First
            </h2>
            <p className="purple-description pad-bottom">
            Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
            </p>
            <div className="op-link op1">
              <a href="/natural-resources" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="link-sky-blue"><span className="op-link-title">Contact Us</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-1"></span></span>
            <h4 className="title-5">Design something new</h4>
            <p className="purple-description">
            <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-2"></span></span>
            <h4 className="title-5">Maintain existing system</h4>
            <p className="purple-description">
            We’ll <span className="light">review and refactor</span> your code for future improvements.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
	<div className="rivet-05">
	  <img className="picture" src={require('../../images/analytics/rivet-lines-sky-blue.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>	
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
</div>
);
}
export default DataVisualization;