/* import React from 'react'; */
import React, { useEffect  } from 'react';

function DroneSatellite (props) {
  useEffect(()=>{
    document.title = "Drone Satellite | Vizonomy";
  },[]);
return (
<div className="DroneSatellite">

</div>
);
}
export default DroneSatellite;