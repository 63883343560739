/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function Government (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'Government';
  useEffect(()=>{
    document.title = "Government | Vizonomy";
  },[]);
  const imagesURLs = [
    'government/bk-img-government-02.png',
    'government/img-carousel-02.png',
    'government/img-carousel-03.png',
    'government/img-carousel-04.png',
    'government/img-carousel-05.png'
  ];
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="Government">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Government</span></h3>
          <h1 className="title-principal">Track KPIs. Develop communication tools. Support data-driven policy.</h1>
          <p className="description">
          A picture is worth a million rows. Expressive visualization enables you to get beyond static charts to create multi-faceted views of data and explore every dimension. At Vizonomy, we help give everyone the power to find answers with an intuitive, interactive, real-time experience.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/government/bk-img-government-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/government/ic_brand-01.svg')} height="70%" width="70%"  alt="aker" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/government/ic_brand-02.svg')} height="60%" width="60%"  alt="drone deploy" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/government/ic_brand-03.svg')} height="60%" width="60%"  alt="sense fly" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/government/ic_brand-04.svg')} height="60%" width="60%" alt="usgs" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">World Bank - Iraq Reconstruction Platform</h1> 
      <p className="description">
      A custom project management tool that helps the World Bank's Iraqi office centralize communication on upcoming development projects. Data streams are connected via API and static uploads, while administrators are also able to validate project data and store ancillary project files (e.g. documents, reports, CSVs and images). All descriptive content is managed by an easy-to-access drupal site.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Database on GeoServer (PostGIS), and MySQL, respectively for spatial and tabular data. Graphics on Mapbox vector tiles and D3.JS. Responsive web framework on React, Ant Design and Bootstrap. Content Management System on Drupal. Hosted on Amazon Web Services.
            </p>
            <div class="op-link">
              <a href="/user-experience" class="link-blue">
                <span class="op-link-title">Our Approach</span>
                <span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Map View</a>
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Card View</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Validate Projects</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Upload Data</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Manage Users</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Ministry of Transportation - Argentina</h1>
          <p>
          Vizonomy works with the Government in Buenos Aires to support understanding of how people move about the city. Through the development of an iOS and Android mobile application, our analysis provides anonymized origin-destination maps which in turn contribute to how trains and buses are scheduled. Furthermore, the Government is able to send spatially-targeted transit surveys in order to solicit feedback on the current system.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/government/bk-img-government-03.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Android and iOS Apps</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Anonymized mobility data collection for origin-destination maps</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Survey builder that may be sent to spatially-targeted areas</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Automatic detection when a user boards a bus or train, and trip review requested upon disembarking</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture</span></span>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-blue">
    <img className="picture" src={require('../../images/climate/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your organization</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Insightful analytics</h4>
          <p class="turq-description">Create and share easy-to-use maps and analytics that improve everyone's understanding. Analyze complex issues and reveal hidden patterns in data. Facilitate informed decision-making.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Rapid data collection</h4>
          <p class="turq-description">Simplify data collection on any device, including smartphones, drones, and sensors. Quickly assemble data from real-time feeds, multiple databases, or any online public data repository.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Simplified sharing</h4>
          <p class="turq-description">Share maps and analytics securely with staff or citizens to support smart initiatives. Dashboards and infographics provide intuitive and effective communication tools.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Civic and stakeholder engagement</h4>
          <p class="turq-description">Impartial and forthright data helps governments gain the necessary trust to build consensus in its priorities.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          United Nations - Jamaica
          </h1>
          <p>
          Designed for UNDP and the Planning Institute of Jamaica, Vizonomy implements a database management system to facilitate the collation, management, analysis and reporting of projects and programs aligned with Jamaica's Vision 2030 plan for the UN's Sustainable Development Goals.
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">API and static data streams</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Attribute-based filtering</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Upload and validate new projects</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">User management system</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Custom views by user type</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="http://ecmis.pioj.gov.jm/login" class="link-blue">
              <span class="op-link-title">View Live Project</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/government/bk-img-government-04.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-light-gray">
    <img className="picture" src={require('../../images/government/bk-rivet-light-gray.svg')}/>
  </div>
  <div className="sec-rw">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>UNDP - Colombo, Sri Lanka</h1>
          <p>
          Vizonomy works with the United Nations Development Programme to help Sri Lanka visualize their progress in achieving the country's 17 sustainable development goals (SDGs). Each goal has its own page, where individual funders can be assessed over a moving timeline. As progress is made, UN Staff are able to upload data through a custom, password-protected data portal.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/government/bk-img-government-05.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">User-friendly data portal on Sri Lanka's SDGs</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data uploader for UNDP staff</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Dynamic tables and graphics</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Spatial framework established for future phases</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Mobile-friendly</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Drupal content management system</span></span>
          </div>
          <div class="op-link">
            <a href="http://finance.sdg.lk/" class="btn-blue">
              <span class="op-link-title">View Live Project</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Government;