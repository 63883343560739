/* import React from 'react'; */
import React, { useEffect  } from 'react';

function Analytics (props) {
  let pageName = 'Analytics';
  useEffect(()=>{
    document.title = "Analytics | Vizonomy";
  },[]);
return (
<div className="Analytics">
</div>
);
}
export default Analytics;