
import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import {sendEmail} from './ContactService';

function Contact () {
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownReasonValue, setDropdownReasonValue] = useState('General Inquiry');
  const [fullName, setfullName] = useState('');
  const [mail, setmail] = useState('');
  const [city, setcity] = useState('');
  const [reason, setreason] = useState('');
  const [something, setsomething] = useState('');
  const [visible, setVisible] = useState(false)


  const toggle = () => setDropdownOpen(prevState => !prevState);
  const changeValue = (val) => {
    setreason(val);
    setDropdownReasonValue(val)
  };
  const sendContact = () =>{ 
    let body = {
      fullName, mail, city, reason, something
    }
    sendEmail(body).then((data)=>{
      if(data.status===200) {
        setVisible(true);
      }
    }).catch((err)=>{
      console.log("Error", err);
    });
  }
  const onDismiss = () => setVisible(false);
  useEffect(()=>{
    document.title = "Contact Us | Vizonomy";
  },[]);

return (
<div className="Contact">
  
  <div className="sec-a">
    <div className="bk-disposition">
    </div>
    <div className="bk-light-blue">
    </div>
    <div className="newviz-content">
      <div className="show-content">
        <div className="presentation">
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-1">
              <h1 className="title-principal">
              Contact our sales team
              </h1>
              <p className="description">
              Our team is happy to answer your sales questions. Fill out the form and we’ll be in touch as soon as possible.
              </p>
            </div>
            <div className="col-6 mx-0 px-0 block-2">
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-1">
            </div>
            <div className="col-36mx-0 px-0 block-2">
            </div>
          </div>
        </div>  
        
        <div className="bk-theme-2">
        {/* <Alert className="alert-contact" color="info" isOpen={visible} toggle={onDismiss}>
          <div>
            <p>THANKS FOR GETTING IN TOUCH </p>
            <br></br>
            <p>We will follow up with you shortly.</p>
          </div>
        </Alert> */}
          <div className="right-side">
            <div className="show-part">
              <div className="box-form">
              
                <div className="form-data">
                  <div className="container">
                    <div className="row area-title">
                      <div className="col-12 head">
                        <h2 className="purple-title-2">You’re in good company</h2>
                        <p className="turq-description">Join our growing list of clients</p>
                      </div>
                    </div>
                    <div className="row f-input">
                      <div className="col-4">
                      <span className="form-title">Your Full Name</span>
                      </div>
                      <div className="col-8">
                        <input type="text" name="fullname" id="fullname" placeholder="ex: John Doe"  onChange={(e)=> setfullName(e.target.value)}/>
                      </div>
                    </div>
                    <div className="row f-input">
                      <div className="col-4">
                      <span className="form-title">Organization Email</span>
                      </div>
                      <div className="col-8">
                        <input type="text" name="email" id="email" placeholder="ex: john.doe@example.com" onChange={(e)=> setmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="row f-input">
                      <div className="col-4">
                      <span className="form-title">City</span>
                      </div>
                      <div className="col-8">
                        <input type="text" name="city" id="city" placeholder="ex: Washington DC" onChange={(e)=> setcity(e.target.value)} />
                      </div>
                    </div>
                    <div className="row f-input">
                      <div className="col-4">
                        <span className="form-title">Reason For Contact</span>
                      </div>
                      <div className="col-8">
                      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>
                          {dropdownReasonValue}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={()=> changeValue('General Inquiry')}>General Inquiry</DropdownItem>
                          <DropdownItem onClick={()=> changeValue('Partnerships')}>Partnerships</DropdownItem>
                          <DropdownItem onClick={()=> changeValue('Job Opportunities')}>Job Opportunities</DropdownItem>
                          <DropdownItem onClick={()=> changeValue('Press Inquiry')}>Press Inquiry</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      </div>
                    </div>
                    <div className="row f-input">
                      <div className="col-4 title-txt-area">
                      <span className="form-title">Anything Else</span>
                      </div>
                      <div className="col-8">
                        <textarea className="txt-area" name="anythingelse" id="anythingelse" cols="30" rows="10" placeholder="Tell us more about your project, needs, and timeline" onChange={(e)=>setsomething(e.target.value)}></textarea>
                      </div>
                    </div>
                    <div className="row block-send">
                      <div className="col-4">
                      </div>
                      <div className="col-8">
                        <div className="send" >
                          <div className="op-link" onClick={() => sendContact()}>
                            <a href="#" className="btn-blue-dark" >
                              <span className="op-link-title">Contact sales</span>
                              <span className="arrow-white"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="brands">
          <div className="row">
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-01.svg')} height="70%" alt="siemens" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-02.svg')} height="80%" alt="instacart" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-03.svg')} height="80%" alt="the world bank" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-04.svg')} height="80%" alt="gileal" /></div>
          </div>
          <div className="row">
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-05.svg')} height="80%" alt="undp" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-06.svg')} height="80%" alt="carto" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-07.svg')} height="80%" alt="national geographic" /></div>
            <div className="col-3"><img className="picture" src={require('../../images/main/brand-08.svg')} height="70%" alt="aecom" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="rivet-globe">
    <img className="picture" src={require('../../images/main/rivet-globe-1.svg')}/>
  </div>
  <div className="rivet-globe-small">
    <img className="picture" src={require('../../images/main/rivet-globe-small-1.svg')}/>
  </div>
  <div className="sec-e">
    <div className="bk-image">
    </div>
    <div className="float-canvas">
      <div className="bk-disposition">
        <div className="newviz-content-5">
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0 block-1">
              <h1 className="turq-title">
              Discover Insights
              </h1>
              <h2 className="turq-title-2">
              View patterns at any scale
              </h2>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-6 mx-0 px-0 block-2">
              <p className="turq-description pad-bottom">
              Our clients have data structures of all kinds: from uncleaned CSVs and messy shapefiles to complex web mapping services, APIs and more. We normalize your data, store it on-premise or in the cloud, and deliver it to your global users efficiently. We do this through a combination of open source tools such as <span className="light">MongoDB, MySQL Node.JS, and others.</span>
              </p>
            </div>
            <div className="col-6 mx-0 px-0 block-2">
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Identify relationships over time</h4>
              <p className="turq-description">
              Uncover causal effects, growth patterns and hiearchy.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Develop spatial trigger events</h4>
              <p className="turq-description">
              Send emails or messages based on spatial rules.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Monitor projects in real time</h4>
              <p className="turq-description">
              Upload media, View status changes, and Manage staffing.
              </p>
            </div>
            <div className="col-3 mx-0 px-0 block-2">
              <h4 className="turq-title-3">Data mine social media</h4>
              <p className="turq-description">
              Query by keyword across Twitter, Facebook or Snap.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="video-area">
      <div className="show-d">
        <div className="main-top-bk-3">
           <video className="shape-mask" autoPlay muted loop preload >
               <source src={require('../../images/main/movie-02.mp4')} type="video/mp4"/>
           </video>
        </div>
      </div>
    </div>
  </div>

  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className={"alert-info"  + (visible? " active":"")}  >
    <div className="show-area">
      <div className="side-a">
        <span className="ic-notify"></span>
      </div>
      <div className="side-b">
        <span className="title">Yay! Thanks for getting in touch!</span>
        <span className="description">We will follow up with you shortly.</span>
      </div>
      <div className="side-c">
        <a href="#" className="close" onClick={()=>onDismiss()}><span className="ic-close"></span></a>
      </div>
    </div>
  </div>
</div>
  );
}
export default Contact;