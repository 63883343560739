import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselView({index, imagesurls}) {
    
        return (
          <div>
            <Carousel interval={null} activeIndex={index}>
              { imagesurls.map( (url) => 
                  <Carousel.Item>
                    <img
                      className="picture"
                      src={require('../../images/'+url)}
                      height="500px"
                    />
                  </Carousel.Item>)
              }
            </Carousel>
          </div>
        )

};





