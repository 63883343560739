/* import React from 'react'; */
import React, { useState,useEffect } from 'react';

function Solutions (props) {
  useEffect(()=>{
    document.title = "Solutions | Vizonomy";
  },[]);
return (
<div className="Solutions">

  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Natural Resources</span></h3>
          <h1 className="title-principal">From conservation monitoring to climate risk analytics</h1>
          <p className="description">
          Our custom solutions enable you to streamline workflows and maximize productivity. Spatial insights reveal new patterns and trends to help you make better-informed decisions to help your organization thrive.
          </p>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">Our Work</span><span class="arrow-white"></span>
            </a>
            <a href="#" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/solutions/bk-img-solution-01.png')} />
      </div>
    </div>
    <div className="rivet-nr">
      <img src={require('../../images/solutions/bk-img-solution-02.png')} />
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-01.svg')} height="70%" alt="siemens" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-02.svg')} height="80%" alt="instacart" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-03.svg')} height="80%" alt="the world bank" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-04.svg')} height="80%" alt="gileal" /></div>
      </div>
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-05.svg')} height="80%" alt="undp" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-06.svg')} height="80%" alt="carto" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-07.svg')} height="80%" alt="national geographic" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/main/brand-08.svg')} height="70%" alt="aecom" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    {/* <div className="bk-grid"></div> */}
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">Mile High Flood District</h1> 
      <p className="description">
      A custom project management tool that helps the Denver-based organization centralize communication, planning and project tracking across its hundreds of flood mitigation projects and dozens of staff.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Databases on CARTO and MySQL, respectively for spatial and tabular data. Graphics on Mapbox and D3.JS. Responsive web framework on React, Ant Design, and Bootstrap. Hosted on Google Cloud.
            </p>
            <div class="op-link">
              <a href="#" class="link-blue">
                <span class="op-link-title">Our Approach</span>
                <span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className="active" href="#">Mapping</a>
              <a href="#">Visualization</a>
              <a href="#">CommentingSystem</a>
              <a href="#">Data Pipeline</a>
              <a href="#">Profile</a>
            </div>
            <div className="area-picture">
              <div className="item">
              <img className="picture" src={require('../../images/solutions/bk-img-solution-03.png')} height="500px" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your production</h1>
        </div>
      </div>
      <div class="row mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="uc-01"></span></div>
          <h4 class="turq-title-3">Turn science into action</h4>
          <p class="turq-description">Centralize your operations, perform analyses, and facilitate communication across stakeholders.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="uc-02"></span></div>
          <h4 class="turq-title-3">Connect land, people, & biodiversity</h4>
          <p class="turq-description">Access data from multiple disciplines and layer it onto a map.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="uc-03"></span></div>
          <h4 class="turq-title-3">Preserve forests, rivers, & watersheds</h4>
          <p class="turq-description">Empower your team to see areas of concern with geospatial intelligence.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="uc-04"></span></div>
          <h4 class="turq-title-3">Weather changes everything</h4>
          <p class="turq-description">Build temporal and spatial correlations between changing weather patterns and your interests.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-blue-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Centralize your data
          </h1>
          <p>
          Vizonomy will work with your team to determine the best data architecture: whether it's synchronizing ESRI service layers into your map or creating new data tables in a cloud environment. We work with you to optimize the data structure so that we can achieve the highest performance. Data types we work with include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Media</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">PDFs</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Social Media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Shapefiles</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">GeoDatabases</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Imagery</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/solutions/bk-img-solution-04.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>The World Bank - Colombo, Sri Lanka</h1>
          <p>
          A geospatial a visualization solution that centralizes +20GB of documents, excel files, and spatial information related to flood risk in Sri Lanka. Economic losses, property and demographic exposure, and other custom analyses can be performed across the country's 12 major basins. As a result, the platform is helping the government direct investment toward effective flood mitigation and disaster avoidance. Key features include:
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/solutions/bk-img-solution-05.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Polygon-based analyses and flood loss calculations</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Content management system for uploading new datasets and editing documents</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">Our Work</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-blue-2-rivet-bottom">
    <img className="picture" src={require('../../images/solutions/bk-rivet-blue-2.svg')}/>
  </div>
  <div className="sec-features">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h3>Recent Work</h3>
          <h1>Mile High Flood District</h1> 
          <p className="description">
          A custom project management tool that helps the Denver-based organization centralize communication, planning and project tracking across its hundreds of flood mitigation projects and dozens of staff.
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="area-img">
            <img className="picture" src={require('../../images/solutions/bk-img-solution-06.png')}/>
          </div>
          <h3>
          Custom user management
          </h3>
          <p>
          Decide access by screen, dataset, geography or more
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/solutions/bk-img-solution-07.png')}/>
          </div>
          <h3>
          Low-latency mapping
          </h3>
          <p>
          Vector tiles provided by Mapbox. GeoServer, or CARTO
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/solutions/bk-img-solution-08.png')}/>
          </div>
          <h3>
          Responsive interfaces
          </h3>
          <p>
          Mobile-friendly. Sleek components and purposeful iconography through React and Bootstrap
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/solutions/bk-howdesign-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/solutions/bk-howdesign-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs. </p>
          <div class="op-link">
            <a href="#" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/solutions/bk-howdesign-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="#">Agriculture</a>
              <a href="#">Business Intelligence</a>
              <a href="#">Climate</a>
              <a href="#">Government</a>
              <a href="#">Health</a>
              <a href="#">Natural Resources</a>
              <a href="#">Non-Profit</a>
              <a href="#">Real Estate</a>
              <a href="#">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="#">Our Story</a>
              <a href="#">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="#">User Experience</a>
              <a href="#">GIS and Web Mapping</a>
              <a href="#">Data Visualization</a>
              <a href="#">Mobile and Web</a>
              <a href="#">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="#">Our Blog</a>
              <a href="#">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="#">Agriculture</a>
              <a href="#">Business Intelligence</a>
              <a href="#">Climate</a>
              <a href="#">Government</a>
              <a href="#">Health</a>
              <a href="#">Natural Resources</a>
              <a href="#">Non-Profit</a>
              <a href="#">Real Estate</a>
              <a href="#">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="#">Our Story</a>
              <a href="#">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="#">User Experience</a>
              <a href="#">GIS and Web Mapping</a>
              <a href="#">Data Visualization</a>
              <a href="#">Mobile and Web</a>
              <a href="#">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="#">Our Blog</a>
              <a href="#">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Solutions;