/* import React from 'react'; */
import React, { useState, useEffect  } from 'react';

const Header = ({menuType}) => { 
  // menuType es el que decide si es light o cual 
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);
  const [classMenu, setClassMenu] = useState('menu');
  const [classMenuItem, setClassMenuItem] = useState('menu-item');
  useEffect(()=>{
    if(menuType == 'menu-light') {
      setClassMenu('menu-light');
      setClassMenuItem('menu-item-light');
    }
  },[]);
return (
<div className="Header">
  <div className={openMenu ? "bar-navigation active " : "bar-navigation"}>
    <div className="head-mobile-nav">
      <div className="logo">
        <a href="/main" className="home-link">
          <span className={classMenuItem}></span>
        </a>
      </div>
      <div className="right">
        <button className={classMenu + ' btn-m-mobile'} type="button" aria-label="Menu" aria-controls="navigation" onClick={toggleMenu}>
          <span className="m-box">
            {/* <span className={openMenu ? "m-inner active" : "m-inner"}></span> */}
            <span className={openMenu ? classMenu +' m-inner active' : classMenu +' m-inner'}></span>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div className={openMenu ? "mob-bk-main-nav active" : "mob-bk-main-nav"} >
    <div className="area-main-nav">
      <nav className="option-mobile-nav">
        <span className="menu-head">
          <span className="title">SOLUTIONS</span>
          <a href="#" className="close" onClick={toggleMenu} ><span className="ic-close-menu"></span></a>
        </span>
        <ul className="option-a">
          <li><a href="/agriculture"><span className="ic-sol-01"></span><span className="l-title">Agriculture</span></a></li>
          <li><a href="/business-intelligence"><span className="ic-sol-02"></span><span className="l-title">Business</span></a></li>
          <li><a href="/climate"><span className="ic-sol-03"></span><span className="l-title">Climate</span></a></li>
          <li><a href="/government"><span className="ic-sol-04"></span><span className="l-title">Government</span></a></li>
          <li><a href="/health"><span className="ic-sol-05"></span><span className="l-title">Health</span></a></li>
          <li><a href="/natural-resources"><span className="ic-sol-06"></span><span className="l-title">Environmental</span></a></li>
          <li><a href="/non-profit"><span className="ic-sol-07"></span><span className="l-title">Non-Profit</span></a></li>
          <li><a href="/real-estate"><span className="ic-sol-08"></span><span className="l-title">Real Estate</span></a></li>
          <li><a href="/smart-cities"><span className="ic-sol-09"></span><span className="l-title">Smart Cities</span></a></li>
        </ul>
        <div className="br-line"><span className="line"></span></div>
        <ul className="option-a">
          <li><a href="/user-experience"><span className="ic-sol-10"></span><span className="l-title">Experience</span></a></li>
          <li><a href="/gis-web-mapping"><span className="ic-sol-11"></span><span className="l-title">Web Mapping</span></a></li>
          <li><a href="/3d-visualization"><span className="ic-sol-12"></span><span className="l-title">3D</span></a></li>
          <li><a href="/real-time-analytics"><span className="ic-sol-13"></span><span className="l-title">Real-Time</span></a></li>
          <li><a href="/drone-satellite"><span className="ic-sol-14"></span><span className="l-title">Imagery</span></a></li>
          <li><a href="/augmented-reality"><span className="ic-sol-15"></span><span className="l-title">AR & VR</span></a></li>
          <li><a href="https://vizonomy.com/company"><span className="ic-sol-16"></span><span className="l-title">About Us</span></a></li>
          <li><a href="/customers"><span className="ic-sol-17"></span><span className="l-title">Customers</span></a></li>
          <li><a href="https://medium.com/vizonomy"><span className="ic-sol-18"></span><span className="l-title">Our Blog</span></a></li>
        </ul>
        <div className="op-link">
          <a href="/contact-us" className="btn-purple"><span className="op-link-title">Contact Us</span><span className="arrow-white"></span></a>
        </div>
      </nav>
    </div>
  </div>
  <div className={openMenu ? "options-navigation" : "options-navigation mod-absolute"}>
    <div className={openMenu ? "main-navbar active" : "main-navbar"}>
    <nav className={openMenu ? "" : "inactive"}>
      <ul className={classMenu}>
        <li className="viz-logo">
            <a href="/main" className="home-link">
              <span className={classMenu}></span>
            </a>
        </li>
        <li className={classMenuItem}>
          <a className="m-tit-sec active" href="#">Solutions</a>
          <ul className="submenu active">
            <li className="submenu-item">
              <div className="canvas">
                <div className="b-left">
                    <span className="overview-title">Overview</span>
                    <span className="overview-descrip m-description">
                      View case studies by industry and learn how our approach can benefit your organization.
                    </span>
                </div>
                <div className="b-right">
                  <div className="row">
                    <div className="col-4 mc1">
                      <a href="/agriculture">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-01"></span></span>
                          <span className="m-title">Agriculture</span>
                        </div>
                        <p className="m-description">
                        Develop remote sensing triggers to track deforestation, vegetative health, logistics monitoring & more.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc2">
                      <a href="/business-intelligence">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-02"></span></span>
                          <span className="m-title">Business intelligence</span>
                        </div>
                        <p className="m-description">
                        Uncover insights across assets, customer segments or other opportunities.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc3">
                      <a href="/climate">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-03"></span></span>
                          <span className="m-title">Climate</span>
                        </div>
                        <p className="m-description">
                        Explore future scenarios & their impact on people & property.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 mc1">
                      <a href="/government">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-04"></span></span>
                          <span className="m-title">Government</span>
                        </div>
                        <p className="m-description">
                        Manage projects or staffing resources. Integrate data sources into a singular, custom platform.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc2">
                      <a href="/health">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-05"></span></span>
                          <span className="m-title">Health</span>
                        </div>
                        <p className="m-description">
                        View health dimensions across geographies & develop temporal insights.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc3">
                      <a href="/natural-resources">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-06"></span></span>
                          <span className="m-title">Natural resources</span>
                        </div>
                        <p className="m-description">
                        Determine connections across land, people, & biodiversity.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 mc1">
                      <a href="/non-profit">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-07"></span></span>
                          <span className="m-title">Non-profit</span>
                        </div>
                        <p className="m-description">
                        Communicate your data through immersive storytelling, interactive mapping, & dynamic graphics.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc2">
                      <a href="/real-estate">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-08"></span></span>
                          <span className="m-title">Real estate</span>
                        </div>
                        <p className="m-description">
                        Understand market competitive strengths, employment trends, & key insight.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc3">
                      <a href="/smart-cities">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-solutions-09"></span></span>
                          <span className="m-title">Smart cities</span>
                        </div>
                        <p className="m-description">
                        Manage & predict outages, track asset status, & comply with regulations with intuitive dashboards.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li className={classMenuItem}>
          <a className="m-tit-sec" href="#">Analytics</a>
          <ul className="submenu">
            <li className="submenu-item">
              <div className="canvas">
                <div className="b-left">
                    <span className="overview-title">Overview</span>
                    <span className="overview-descrip m-description">
                    We build algorithms and models that enhance organizations quickly, efficiently, and cost-effectively.
                    </span>
                </div>
                <div className="b-right">
                  <div className="row">
                    <div className="col-6 mc1">
                      <a href="#" >
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-analytics-01"></span></span>
                          <span className="m-title">Drone & satellite</span>
                        </div>
                        <p className="m-description">
                        Integrate custom imagery & discover patterns based with powerful raster-based analytics.
                        </p>
                      </a>
                    </div>
                    <div className="col-6 mc2">
                      <a href="#">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-analytics-02"></span></span>
                          <span className="m-title">3D visualization</span>
                        </div>
                        <p className="m-description">
                        Transform your spatial data into immersive experiences using open source libraries.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mc1">
                      <a href="#">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-analytics-03"></span></span>
                          <span className="m-title">Real-time analytics</span>
                        </div>
                        <p className="m-description">
                        Reduce data latency across sources for optimal performance on your customized dashboard. Integrate social media, photos, documents, & unstructured data.
                        </p>
                      </a>
                    </div>
                    <div className="col-6 mc2">
                      <a href="#">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-analytics-04"></span></span>
                          <span className="m-title">Machine learning</span>
                        </div>
                        <p className="m-description">
                        Scale computer vision, statistical, & machine learning models to inform business decisions.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li className={classMenuItem}>
          <a className="m-tit-sec" href="#">Design</a>
          <ul className="submenu">
            <li className="submenu-item">
              <div className="canvas">
                <div className="b-left">
                    <span className="overview-title">Overview</span>
                    <span className="overview-descrip m-description">
                    Designed with intent, our custom dashboards and tools are available across resolutions, devices and browsers.
                    </span>
                </div>
                <div className="b-right">
                  <div className="row">
                    <div className="col-4 mc1">
                      <a href="/user-experience">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-design-01"></span></span>
                          <span className="m-title">User experience</span>
                        </div>
                        <p className="m-description">
                        Craft user roles & workflow with our dedicated design sprint team. Empathize with your audience & be strategic in your design decisions.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc2">
                      <a href="/gis-web-mapping">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-design-02"></span></span>
                          <span className="m-title">GIS & web mapping</span>
                        </div>
                        <p className="m-description">
                        Develop fluid & immersive mapping experiences with robust data pipelines across APIs, databases, & more.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc3">
                      <a href="/data-visualization">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-design-03"></span></span>
                          <span className="m-title">Data visualization</span>
                        </div>
                        <p className="m-description">
                        Offer beautiful visualizations from the most commonplace to the most unique. Simplify complexity with custom dashboards.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 mc1">
                      <a href="/mobile-web">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-design-04"></span></span>
                          <span className="m-title">Mobile & web</span>
                        </div>
                        <p className="m-description">
                        Responsive design leveraging the most modern web frameworks from React, Node, & Bootstrap.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc2">
                      <a href="/augmented-reality">
                        <div className="mhead">
                          <span className="sec-icon"><span className="ic-viz ic-design-05"></span></span>
                          <span className="m-title">Augmented reality</span>
                        </div>
                        <p className="m-description">
                        Present your data through new experiences. Overlay the digital world over the physical world.
                        </p>
                      </a>
                    </div>
                    <div className="col-4 mc3">
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
        {/* <li className="menu-item optwo"> */}
        <li className={classMenuItem + ' optwo'}>
          <a className="m-tit-sec" href="#">Company</a>
          <ul className="submenu active">
            <li className="menu-vertical">
              <ul>
                <li><a href="https://vizonomy.com/company"><span className="ic-viz ic-company-01"></span><span className="title">About Us</span></a></li>
                <li><a href="/customers"><span className="ic-viz ic-company-02"></span><span className="title">Customers</span></a></li>
                {/* <li><a href="/partners" ><span className="ic-viz ic-company-03"></span><span className="title">Partners</span></a></li> */}
                <li><a href="https://medium.com/vizonomy"><span className="ic-viz ic-company-04"></span><span className="title">Our Blog</span></a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="op-contactus">
          <div className="right-side">
              <span>
                <a href="/contact-us" className="link-contactus">Contact Us&nbsp;&nbsp;<span className="arrow"></span></a>
              </span>
            </div>
        </li>
        <li className="menu-item brandviz">
          <span className="m-tit-sec" href="/main">© Vizonomy 2020</span>
        </li>
      </ul>
    </nav>
  </div>
  </div>
</div>
);
}
export default Header;