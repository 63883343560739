import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function Nonprofit (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'Non Profit';
  useEffect(()=>{
    document.title = "Non-Profit | Vizonomy";
  },[]);
  const imagesURLs = [
    'nonprofit/bk-img-nonprofit-02.png',
    'nonprofit/img-carousel-02.png',
    'nonprofit/img-carousel-03.png',
    'nonprofit/img-carousel-04.png',
    'nonprofit/img-carousel-05.png'
  ];
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="Nonprofit">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Non-Profit</span></h3>
          <h1 className="title-principal">Increase organizational impact, measure KPIs, and analyze changes at scale</h1>
          <p className="description">
          Use mapping tools to monitor and evaluate operations in real time, and measure progress. Our customized tools have monitored infrastructure projects across Peru, doctors and medical staff throughout Kenya, and weather patterns in real-time.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/nonprofit/bk-img-nonprofit-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-01.svg')} height="50%" width="50%"  alt="digital globe" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-02.svg')} height="35%" width="35%"   alt="arcadis" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-03.svg')} height="45%" width="45%"  alt="respec" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-05.svg')} height="40%" width="40%" alt="mhfd" /></div>
      </div>
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-06.svg')} height="35%" width="35%"  alt="aecom" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-08.svg')} height="35%" width="35%"  alt="the world bank" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-07.svg')} height="50%" width="50%"  alt="exponent" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/nonprofit/ic_brand-04.svg')} height="60%" width="60%" alt="parsons" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">UNDP - Global Monitoring Dashboard</h1> 
      <p className="description">
      Vizonomy worked with the United Nations Development Programme to create this Monitoring Dashboard to help people easily find how each country is accomplishing the 2030 Sustainable Development Goals (SDGs). The tool’s emphasis on ease of use, clear calls-to-action, and effective messaging gives the UN an active and engaging platform to drive tangible action and quickly respond to current policy and issues.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Powered by CARTO, a geospatial provider. Graphics on Mapbox and D3.JS. Responsive web framework on Angular, Ant Design, and Bootstrap. Hosted on-premise.
            </p>
            <div class="op-link">
              <a href="http://dashboard.effectivecooperation.org/" class="link-blue">
                <span class="op-link-title">View Live Site</span>
                <span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Map Explorer</a>
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Data Comparison</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Filtering</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Graphics</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Uploader</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your organization</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Human centered design</h4>
          <p class="turq-description">We spend time learning about the issue and developing prototypes with your team. During the tool development phase, your team has access to the product in real-time.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Mapping and visualization</h4>
          <p class="turq-description">Leverage OpenStreet Map, terrain data, social media, sensor data, and crowdsourced information in an integrated and insightful dashboard.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Data pipeline creation</h4>
          <p class="turq-description">From making your data secure to developing various strategies to integrate your data with your existing system, we ensure that your data architecture is reliable.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Custom imagery</h4>
          <p class="turq-description">Remote sensing is our speciality. From obtaining imagery from drones or satellites to developing indices that detect changes in vegetation and urban areas, we do it all.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-blue-gray-2">
    <img className="picture" src={require('../../images/health/bk-rivet-blue-gray-2.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Peace Tech Labs - CoVID-19 Violence Tracker</h1>
          <p>
          PTL reached out to Vizonomy through our partnership with Mapbox in Washington DC. In less than four weeks, our design team implemented a full-suite solution that is helping the non-profit identify and geo-reference all articles related to increased violence during the pandemic.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/nonprofit/bk-img-nonprofit-04.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Custom basemaps from Mapbox</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data architecture built on Google Forms and Google Sheets that feed into the data portal automatically</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Attribute and spatial-based filtering</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Synchronous visualizations</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Cloud-based architecture on Amazon Web Services</span></span>
          </div>
          <div class="op-link">
            <a href="http://54.165.80.182/" class="btn-blue">
              <span class="op-link-title">See Live Site</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-white">
    <img className="picture" src={require('../../images/health/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Centralize your data
          </h1>
          <p>
          Vizonomy will work with your team to determine the best data architecture: whether it's synchronizing ESRI service layers into your map or creating new data tables in a cloud environment. We work with you to optimize the data structure so that we can achieve the highest performance. Data types we work with include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Media</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">PDFs</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Social Media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Shapefiles</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">GeoDatabases</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Imagery</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="link-blue">
              <span class="op-link-title">Our Approach</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/nonprofit/bk-img-nonprofit-05.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-light-gray">
    <img className="picture" src={require('../../images/nonprofit/bk-rivet-light-gray.svg')}/>
  </div>
  <div className="sec-rw-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>United Nations - Dengue Portal</h1>
          <p>
          Vizonomy and the UN began working together with an ambitious goal: to leverage the organization’s digital ecosystem to position with respect to visualizing epidemic outbreaks. We worked with them to rethink their system architecture, data pipeline, and open source libraries from the ground up and optimize how they are attracting, engaging, and growing their target audiences, including donors and supporters.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/nonprofit/bk-img-nonprofit-06.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Content management system for uploading new datasets and editing documents</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-white">
    <img className="picture" src={require('../../images/health/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-features">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Most Common Features</h4>
          <h1>World Wildlife Fund - Climate Crowd</h1> 
          <p className="description">
          A new site for WWF, dedicated to tracking climate-related stories aroudn the world. Built around a new Content Management System, the site also features a custom crowdsourcing form, an administrative portal, and a digital mapping experience.
          </p>
          <div class="op-link">
            <a href="https://www.wwfclimatecrowd.org" class="link-blue">
              <span class="op-link-title">View Live Site</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="row list-systems">
        <div className="col-4">
          <div className="area-img">
            <img className="picture" src={require('../../images/nonprofit/im-cr-01.png')}/>
          </div>
          <h3>
          Content Management System
          </h3>
          <p>
          A custom, Wordpress-based system that enable WWF to replace any text, images, and individual climate stories.
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">Mobile and Web</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/nonprofit/im-cr-02.png')}/>
          </div>
          <h3>
          Low-latency mapping
          </h3>
          <p>
          Vector tiles provided by Mapbox. GeoServer, or CARTO
          </p>
          <div class="op-link">
            <a href="#" class="link-blue">
              <span class="op-link-title">GIS & Web Mapping</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
          <div className="area-img">
          <img className="picture" src={require('../../images/nonprofit/im-cr-03.png')}/>
          </div>
          <h3>
          Responsive interfaces
          </h3>
          <p>
          Mobile-friendly. Sleek components and purposeful iconography through React and Bootstrap
          </p>
          <div class="op-link">
            <a href="/user-experience" class="link-blue">
              <span class="op-link-title">Our Approach</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/government/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Nonprofit;