/* import React from 'react'; */
import React, { Component, useState, useEffect } from 'react';

function Partners (props) {
  const [selectedDescription, setSelectedDescription] = useState(0);
  useEffect(()=>{
    document.title = "Partners | Vizonomy";
  },[]);
return (
<div className="Partners">
</div>
);
}
export default Partners;