/* import React from 'react'; */
import React, { Component, useEffect } from 'react';

function CaseStudies (props) {
  useEffect(()=>{
    document.title = "Case Studies | Vizonomy";
  },[]);
return (
<div className="CaseStudies">

</div>
);
}
export default CaseStudies;