/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function SmartCities (props) {
  const [currentImage, setCurrentImage] = useState('mapping');
  const [indexImage, setIndexImage] = useState(0);
  let pageName = 'Smart Cities';
  useEffect(()=>{
    document.title = "Smart Cities | Vizonomy";
  },[]);
  const imagesURLs = [
    'smartcities/bk-img-smartcities-03.png',
    'smartcities/bk-img-smartcities-03.png',
    'smartcities/bk-img-smartcities-03.png',
    'smartcities/bk-img-smartcities-03.png',
    'smartcities/bk-img-smartcities-03.png'
  ];
  const selectImage = (value)=>{
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(0);
    }
    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
return (
<div className="SmartCities">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Smart Cities</span></h3>
          <h1 className="title-principal">Smart communities achieve data-driven performance</h1>
          <p className="description">
          Whether you generate, transmit, distribute or consume energy; optimize cell tower distribution; or manage and predict storm water runoff, GIS and visualizations are key. Vizonomy has worked with utilities and startups from CityScan to Vodafone to deliver high-performance and reliable solutions.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/smartcities/bk-img-smartcities-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/smartcities/ic_brand-01.svg')} height="80%" width="80%" alt="altus" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/smartcities/ic_brand-02.svg')} height="50%" width="50%" alt="parsons" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/smartcities/ic_brand-03.svg')} height="50%" width="50%" alt="perkins will" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/smartcities/ic_brand-04.svg')} height="60%" width="60%" alt="reindicar" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Siemens</h1>
          <p>
          Get the most out of your location technology investment by exploiting a combination of Internet of Things (IoT) devices, big data, inclusive apps, and community feedback to gain previously unimagined insight. Track wastebin capacity, luminosity status, bridge tensile strength, or other variables - in real time.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/smartcities/bk-img-smartcities-02.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Unique visualizations e.g. timelines, spider graphs, and stacked bar charts</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="/contact-us" class="btn-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your production</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Build a system of record</h4>
          <p class="turq-description">Organize and integrate information from across your organization to better support your agency's objectives. Share information using any device, anywhere, anytime.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Manage critical assets</h4>
          <p class="turq-description">With cloud-based GIS, it's even easier to take advantage of location-based planning for accounting and network operations. Manage assets and outages, serve customers, facilitate field operations, and create situational awareness.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Assess utility performance</h4>
          <p class="turq-description">Find the best places to build a new facility, map your current facilities, and manage nearby environmental factors, such as weather conditions and economic variables.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Manage stormwater in real-time</h4>
          <p class="turq-description">Integrate crowdsourced data - from customized apps - with your internal and external sources. Develop engaging maps the help make you strategic, justifiable choices.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-blue">
    <img className="picture" src={require('../../images/climate/bk-rivet-blue.svg')}/>
  </div>
  <div className="sec-recent-w">
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">Siemens</h1> 
      <p className="description">
      Vizonomy built an intuitive geospatial interface that empowers transit engineers from Siemens to perform vehicle capacity and occupancy analysis across train stations in Germany. Traffic data is visualized in real-time to detect anomalies or predict future passenger flow.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Databases on GeoServer, MySQL and MongoDB. Graphics on Mapbox and D3.JS. Responsive web framework in Angular, Ant Design, and Bootstrap. Hosted on Amazon Web Services.
            </p>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Custom Styling</a>
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Login Interface</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Unique Graphics</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Relational Analysis</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Temporal Filtering</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Ministry of Transport - Argentina</h1>
          <p>
          Vizonomy launch customized apps for iOS and Android to collect anonymized data and send surveys to the public. As a result, the public has another mechanism to submit requests, respond to government feedback, and have a voice in their community's development. Our other solutions also enable for white-labeling custom surveys and visualizing feedback in real-time through a personalized dashboard.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/smartcities/bk-img-smartcities-04.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data centralization for API and static streams</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector tile mapping for efficient rendering on mobile devices</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Content management system for uploading new datasets and editing documents</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Flexible data architecture for new basins</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">On-premise architecture for security</span></span>
          </div>
          <div class="op-link">
            <a href="/contact-us" class="btn-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          Centralize your data
          </h1>
          <p>
          Vizonomy will work with your team to determine the best data architecture: whether it's synchronizing ESRI service layers into your map or creating new data tables in a cloud environment. We work with you to optimize the data structure so that we can achieve the highest performance. Data types we work with include:
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Media</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">PDFs</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Social Media</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Shapefiles</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">GeoDatabases</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Imagery</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="/data-visualization" class="link-blue">
              <span class="op-link-title">See All Features</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/smartcities/bk-img-smartcities-05.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-5">
    <img className="picture" src={require('../../images/solutions/bk-rivet-white.svg')}/>
  </div>
  
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/smartcities/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/smartcities/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/smartcities/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default SmartCities;