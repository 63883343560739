/* import React from 'react'; */
import React, { Component, useState, useEffect } from 'react';

function OurApproach (props) {
  useEffect(()=>{
    document.title = "Our Approach | Vizonomy";
  },[]);
return (
<div className="OurApproach">
</div>
);
}
export default OurApproach
;