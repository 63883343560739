/* import React from 'react'; */
import React, { useState,useEffect } from 'react';
import CarouselView from '../Carousel/Carousel';
function Health (props) {
  const [currentImage, setCurrentImage] = useState('visualization');
  const [currentImage2, setCurrentImage2] = useState('visualization');
  const [indexImage, setIndexImage] = useState(0);
  const [indexImage2, setIndexImage2] = useState(0);
  let pageName = 'Health';
  useEffect(()=>{
    document.title = "Health | Vizonomy";
  },[]);
  // NELSON, en cada componente solo hay que cambiar la ruta de la imagen aqui 
  const imagesURLs = [
    'health/carousel-g1-01.png',
    'health/carousel-g1-02.png',
    'health/carousel-g1-03.png',
    'health/carousel-g1-04.png',
    'health/carousel-g1-05.png'
  ];
  const imagesURLs2 = [
    'health/carousel-g2-01.png',
    'health/carousel-g2-02.png',
    'health/carousel-g2-03.png',
    'health/carousel-g2-04.png',
    'health/carousel-g2-05.png'
  ];
  const selectImage = (value)=>{

    if(value === 'visualization') {
      setCurrentImage('visualization');
      setIndexImage(0);
    }
    if(value === 'mapping') {
      setCurrentImage('mapping');
      setIndexImage(1);
    }
    if(value === 'commenting') {
      setCurrentImage('commenting');
      setIndexImage(2);
    }
    if(value === 'datapipeline') {
      setCurrentImage('datapipeline');
      setIndexImage(3);
    }
    if(value === 'profile') {
      setCurrentImage('profile');
      setIndexImage(4);
    }
  }
  const selectImage2 = (value)=>{

    if(value === 'visualization') {
      setCurrentImage2('visualization');
      setIndexImage2(0);
    }
    if(value === 'website') {
      setCurrentImage2('website');
      setIndexImage2(1);
    }
    if(value === 'dataexplorer') {
      setCurrentImage2('dataexplorer');
      setIndexImage2(2);
    }
    if(value === 'countrycomparison') {
      setCurrentImage2('countrycomparison');
      setIndexImage2(3);
    }
    if(value === 'mapping') {
      setCurrentImage2('mapping');
      setIndexImage2(4);
    }
  }
return (
<div className="Health">
  <div className="sec-nr">
    <div className="bk-grid"></div>
    <div className="content-nr">
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h3><span className="ic-nr"></span><span className="title">Health</span></h3>
          <h1 className="title-principal">Visualize vulnerable populations, identify resources and enable disease surveillance</h1>
          <p className="description">
          Tackle issues at the forefront of public health with intelligent maps and analysis. Manage pandemics, prevent chronic disease, and monitor environmental quality to transform the health of your community.
          </p>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/health/bk-img-health-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
    <div className="brands-nr">
      <div className="row">
        <div className="col-3"><img className="picture" src={require('../../images/health/ic_brand-01.svg')} height="60%" width="60%"  alt="gilead" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/health/ic_brand-02.svg')} height="50%" width="50%"  alt="nova health" /></div>
        <div className="col-3"><img className="picture" src={require('../../images/health/ic_brand-03.svg')} height="60%" width="60%"  alt="pifzer" /></div>
        <div className="col-3"><img className="picture size-30" src={require('../../images/health/ic_brand-04.svg')} height="60%" width="60%" alt="trm" /></div>
      </div>
    </div>
  </div>
  <div className="sec-recent-w">
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">AIDSVu National Dashboard</h1> 
      <p className="description">
      In collaboration with Gilead Sciences, Emory University and the Signal Group, Vizonomy designed a responsive public health dashboard that visualizes the impact of the HIV epidemic on communities across the United State.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Powered by CARTO, a geospatial provider. Graphics on Mapbox and D3.JS. Responsive web framework on Angular, Ant Design, and Bootstrap. Hosted on-premise.
            </p>
            <div class="op-link">
              <a href="https://map.aidsvu.org/map" class="link-blue">
                <span class="op-link-title">View Live Project</span>
                <span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage=='visualization'?"active":'')} onClick={()=>selectImage('visualization')}>Data Comparisons</a>
              <a className={'test ' + (currentImage=='mapping'?"active":'')} onClick={()=>selectImage('mapping')}>Mapping</a>
              <a className={'test ' + (currentImage=='commenting'?"active":'')} onClick={()=>selectImage('commenting')}>Export iFrames</a>
              <a className={'test ' + (currentImage=='datapipeline'?"active":'')} onClick={()=>selectImage('datapipeline')}>Iteractive Pop-ups</a>
              <a className={'test ' + (currentImage=='profile'?"active":'')} onClick={()=>selectImage('profile')}>Data Uploader</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage} imagesurls={imagesURLs}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-usecase">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4>Use Cases</h4>
          <h1>Improve the efficiency of your production</h1>
        </div>
      </div>
      <div class="row bprod mx-0 px-0">
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-01"></span></div>
          <h4 class="turq-title-3">Plan</h4>
          <p class="turq-description">See where your patients live and locate clinics and other facilities where they will be most accessible.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-02"></span></div>
          <h4 class="turq-title-3">Analyse</h4>
          <p class="turq-description">Identify clusters of illness among your patients to determine potential environmental causes or spread patterns.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-03"></span></div>
          <h4 class="turq-title-3">Optimize</h4>
          <p class="turq-description">From routing emergency services to effectively managing available beds, get the most out of your resources.</p>
        </div>
        <div class="col-3 mx-0 px-0 block-2">
          <div className="area-ic"><span className="icon uc-04"></span></div>
          <h4 class="turq-title-3">Public health management</h4>
          <p class="turq-description">Create strategies with GIS to improve systems that affect the health of the community.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-blue-gray-2">
    <img className="picture" src={require('../../images/health/bk-rivet-blue-gray-2.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Aetna Map and Flyer Generator</h1>
          <p>
          Aetna's communications team reached out to Vizonomy for a cost-cutting solution when developing their map-centric flyers. Through a custom, online map generator solution, our team helped reduce the cost of each flyer by 75%. Aetna is able to upload new datasets, alter texts, and select various basemaps based on their regional needs.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/health/bk-img-health-07.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Powered by CARTO, a geospatial provider</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Graphics on Mapbox and D3.JS.</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Responsive web framework on Angular, Ant Design, and Bootstrap.</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Hosted on-premise.</span></span>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-white">
    <img className="picture" src={require('../../images/health/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-world-health">
    <div className="container px-0 b-top mx-0 px-0">
      <h3 className="title-rw">Recent Work</h3>
      <h1 className="title">World Health Organization - HPV Dashboard</h1> 
      <p className="description">
      An internal analytics tools for assessing HPV vaccine coverage globally. Users are able to compare countries, display over +50 health dimensions, export and share results, and update the data through a custom uploader.
      </p>
    </div>
    <div className="area-carousel">
      <div className="container px-0">
        <div className="row">
          <div className="col-3 area-a">
            <span class="icon-wwd"><span class="ic-tech"></span></span>
            <h4 class="title-5">Technology stack</h4>
            <p class="purple-description">
            Powered by CARTO, a geospatial provider. Graphics on Mapbox and D3.JS. Responsive web framework on Angular, Ant Design, and Bootstrap. Hosted on-premise.
            </p>
          </div>
          <div className="col-9 area-b">
            <div className="small-nav">
              <a className={'test ' + (currentImage2=='visualization'?"active":'')} onClick={()=>selectImage2('visualization')}>Visualization</a>
              <a className={'test ' + (currentImage2=='website'?"active":'')} onClick={()=>selectImage2('website')}>Website CMS</a>
              <a className={'test ' + (currentImage2=='dataexplorer'?"active":'')} onClick={()=>selectImage2('dataexplorer')}>Data Explorer</a>
              <a className={'test ' + (currentImage2=='countrycomparison'?"active":'')} onClick={()=>selectImage2('countrycomparison')}>Country Comparison</a>
              <a className={'test ' + (currentImage2=='mapping'?"active":'')} onClick={()=>selectImage2('mapping')}>Mapping</a>
            </div>
            <div className="area-picture">
              <CarouselView index={indexImage2} imagesurls={imagesURLs2}></CarouselView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-lightgray-2">
    <img className="picture" src={require('../../images/health/bk-rivet-light-gray.svg')}/>
  </div>
  <div className="sec-recent-work-2">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Recent Work</h4>
          <h1>Palladium - CIP Performance Dashboard</h1>
          <p>
          The Washington DC-based think reached out to Vizonomy to design a new generation of the Cost Improvement Plan (CIP) dashboard. Its focus is to improve the enabling environment for equitable and sustainable health services, supplies, and delivery systems at the country-level. This dashboard improves accessibility and facilitates policy transparency.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/health/bk-img-health-09.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 area-chk">
      <div className="row">
        <div className="col-6">
          <div className="group-chk-2">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Digital experience built on Drupal</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Export reports onto PDF</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Connect to APIs from US AID, various country-level health ministries, and other sources</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Administrative access for data uploading</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Mobile-friendly graphics</span></span>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  </div>
  <div className="sec-rivet-white">
    <img className="picture" src={require('../../images/health/bk-rivet-white.svg')}/>
  </div>
  <div className="sec-central-data">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h1>
          HIV Continuum Dashboard
          </h1>
          <p>
          Designed in partnership with Gilead Sciences, our team at Vizonomy developed a series of interactive health maps that can be viewed across different demographic groups such as age, sex, and race/ethnicity, as well as alongside social determinants of health and overlaid with HIV testing, PrEP, and care service locations. To be released in early 2021.
          </p>
          <div className="group-chk">
            <div className="block-a">
              <span className="chk"><span className="ic-chk"></span><span className="tit">Custom Uploader</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Mobile-Friendly</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Multiple Maps</span></span>
            </div>
            <div className="block-b">
              <span className="chk"><span className="ic-chk"></span><span className="tit">CARTO engine</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">AWS Hosting</span></span>
              <span className="chk"><span className="ic-chk"></span><span className="tit">Share URL</span></span>
            </div>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="link-blue">
              <span class="op-link-title">Our Approach</span>
              <span class="arrow-blue"></span>
            </a>
          </div>
          <div className="bk-central-data">
            <img className="picture" src={require('../../images/health/bk-img-health-10.png')}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-how-we-design">
    <div className="container">
      <h3>
      How We Design
      </h3>
      <h1>
      Building blocks for any solution
      </h1>
      <div className="building-block">
        <div className="side-a">
          <h2>Bringing vision to reality</h2>
          <p>Whether you're monitoring a project, managing limited resources, understanding spatial trends, or communicating your results, all our products start with the design sprint. Through precise sessions, we'll define user roles, imagine potential workflows and prioritize actions. The result is a combined vision inside an immersive prototype.</p>
          <div class="op-link">
            <a href="https://vizonomy.com/user-experience" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/health/im-bb-01.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Integrating data for analysis</h2>
          <p>From archiving 1000s of documents to centralizing 100s of information sources, our data team will assess how to best structure and store your most critical asset. It may be a MySQL database or a GeoServer instance. Or perhaps an unstructured MongoDB table or an Amazon S3 bucket. We find the optimal approach without sacrificing performance.</p>
          <div class="op-link">
            <a href="/real-time-analytics" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/health/im-bb-02.png')}/>
        </div>
      </div>
      <div className="building-block">
        <div className="side-a">
          <h2>Visualizing insight in dashboards</h2>
          <p>Combining interactive maps with user queries and dynamic graphics provide the most useful insights. Relationships can be discerned, hierarchies established, and spatial or temporal trends isolated. We facilitate such learning by building for your needs.</p>
          <div class="op-link">
            <a href="/data-visualization" class="btn-blue">
              <span class="op-link-title">Learn more</span>
              <span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="side-b">
          <img className="picture" src={require('../../images/health/im-bb-03.png')}/>
        </div>
      </div>
    </div>
  </div>
  
  {/* sec-features sec-how-we-design*/}
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
              <a href="/partners">Partners</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
              <a href="/partners">Partners</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);
}
export default Health;