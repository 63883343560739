import React, { Component, useState,  useEffect } from 'react';

function Customer () {
  const [solutionActive, setSolution] = useState(false);
  const [analyticsActive, setAnalytics] = useState(false);
  const [designActive, setDesign] = useState(false);
  const [companyActive, setCompany] = useState(false);
  useEffect(()=>{
    document.title = "Customer | Vizonomy";
  },[]);
return (
<div className="Customer">
    <div className="sec-a">
        <div className="main-top-vid">
            <video id="shape-mask" autoPlay muted loop preload>
                <source src={require('../../images/main/movie-03.mp4')}/>
            </video>
        </div>
        <div className="newviz-content">
            <div className="show-content">
                <div className="presentation presentationx container">
                  	<div className="row mx-0 px-0">
                      	<div className="block-1">
                            <h1 className="title-principal">
                              You're in good company
                            </h1>
                            <p className="description">
                            From Pfizer to the World Bank, National Geographic to Washington DC, we help organizations do more with their data.
                            </p>
                      	</div>
                  	</div>
                </div>
                <div className="bk-theme-2-change">
                	<div className="mover">
                	  	<div className="content_img">
                	  	  	<img className="img-circle-1 breathing_img" src={require('../../images/customer/017.png')} alt=""/>
                	  	  	<img className="img-circle-2 breathing_img_1" src={require("../../images/customer/021.png")} alt=""/>
                	  	  	<img className="img-circle-3 breathing_img" src={require("../../images/customer/008.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-4 breathing_img_1" src={require("../../images/customer/020.png")} alt=""/>
                	  	  	<img className="img-circle-5 breathing_img" src={require("../../images/customer/014.png")} alt=""/>
                	  	  	<img className="img-circle-6 breathing_img_1" src={require("../../images/customer/015.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-7 breathing_img" src={require("../../images/customer/010.png")} alt=""/>
                	  	  	<img className="img-circle-8 breathing_img_1" src={require("../../images/customer/016.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-9 breathing_img" src={require("../../images/customer/013.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-10 breathing_img_1" src={require("../../images/customer/019.png")} alt=""/>
                	  	  	<img className="img-circle-11 breathing_img" src={require("../../images/customer/018.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-12 breathing_img_1" src={require("../../images/customer/009.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-13 breathing_img" src={require("../../images/customer/012.png")} alt=""/>
                	  	</div>
                	  	<div className="content_img_2">
                	  	  	<img className="img-circle-1 breathing_img_1" src={require("../../images/customer/017.png")} alt=""/>
                	  	  	<img className="img-circle-2 breathing_img" src={require("../../images/customer/021.png")} alt=""/>
                	  	  	<img className="img-circle-3 breathing_img_1" src={require("../../images/customer/008.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-4 breathing_img" src={require("../../images/customer/020.png")} alt=""/>
                	  	  	<img className="img-circle-5 breathing_img_1" src={require("../../images/customer/014.png")} alt=""/>
                	  	  	<img className="img-circle-6 breathing_img" src={require("../../images/customer/015.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-7 breathing_img_1" src={require("../../images/customer/010.png")} alt=""/>
                	  	  	<img className="img-circle-8 breathing_img" src={require("../../images/customer/016.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-9 breathing_img_1" src={require("../../images/customer/013.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-10 breathing_img" src={require("../../images/customer/019.png")} alt=""/>
                	  	  	<img className="img-circle-11 breathing_img_1" src={require("../../images/customer/018.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-12 breathing_img" src={require("../../images/customer/009.png")} alt=""/>
                	  	  	<img className="hidden_xs img-circle-13 breathing_img_1" src={require("../../images/customer/012.png")} alt=""/>
                	  	</div>
                	</div>
            	</div>
            </div>
        </div>
		<div className="bk-disposition white">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
    </div>
    <div className="sec-custom-b">
        <div className="newviz-content container px-0">
            <div className="row mx-0 px-0">
                <div className="col-12 px-0">
                    <div className="top">
                        <h1>Government</h1>
                    </div>
                    <div className="central">
                        <div className="side-left">
                            <p>
                            Vizonomy has the necessary credentials to conduct business with the Federal government (DUNS: 076892519) and the expertise required to help agencies navigate long-term digital communication strategically. Our state and federal contract vehicles make it easier for your agency to work with us.
                            </p>
                            <p>
                            Feel secure knowing that our solutions support HTTPS running analyses on your own infrastructure, behind firewalls and on-premise solutions. 
                            </p>
                            <p className="light">
                            View our latest solution: Washington DC's new flood risk management viewer built with the city's Department of the Energy & Environment (DOEE).
                            </p>
                        </div>
                        <div className="side-right">
                            <img className="picture" src={require('../../images/customer/bk-img-01.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="brands container">
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-01.svg')} height="50%" width="50%" alt="dc" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-02.svg')} height="55%" width="55%" alt="annapolis" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-03.svg')} height="60%" width="60%" alt="broward" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-04.svg')} height="60%" width="60%" alt="emeryville" /></div>
            </div>
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-05.svg')} height="45%" width="45%" alt="county of alameda" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-06.svg')} height="40%" width="40%" alt="fremont" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-07.svg')} height="50%" width="50%" alt="livermore" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g1-08.svg')} height="45%" width="45%" alt="leandro" /></div>
            </div>
        </div>
		<div className="bk-disposition">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
    </div>
	<div className="rivet-area gray">
  	  	<div className="top">
  	  	  <img className="picture" src={require('../../images/customer/rivet-05-1.svg')}  alt="logo" />
  	  	</div>
  	  	<div className="bottom">
  	  	  <img className="picture" src={require('../../images/customer/rivet-05-2.svg')}  alt="logo" />
  	  	</div>
  	  	<div className="bk-disposition ">
  	  	  	<div className="container px-0 lines">
  	  	  	  	<div className="row">
  	  	  	  	  	<div className="col-3 lin-a"></div>
  	  	  	  	  	<div className="col-3 lin-b"></div>
  	  	  	  	  	<div className="col-3 lin-c"></div>
  	  	  	  	  	<div className="col-3 lin-d"></div>
  	  	  	  	</div>
  	  	  	</div>
  	  	</div>
  	</div>
    <div className="sec-custom-b">
        <div className="newviz-content container px-0">
            <div className="row mx-0 px-0">
                <div className="col-12 px-0">
                    <div className="top">
                        <h1>Private Sector</h1>
                    </div>
                    <div className="central">
                        <div className="side-left">
                            <p>
                            Through our custom solutions, get the most out of your reporting so you can focus on timely, insightful analysis. With our dashboards and data architectures visualize in real time to track foot-traffic and spend data, marketing effectiveness, staffing needs, asset exposure or dig into key performance indicators within your company.
                            </p>
                            <p>
                            You can even connect to live data and set up alerts to help you discover tomorrow's solutions, before they're problems.
                            </p>
                            <p className="light">
                            We work in insurance, architecture and engineering, healthcare, retail, marketing, and real-estate.
                            </p>
                        </div>
                        <div className="side-right">
                            <img className="picture" src={require('../../images/customer/bk-img-02.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="brands container">
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-01.svg')} height="40%" width="40%" alt="aecom" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-02.svg')} height="50%" width="50%" alt="carto" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-03.svg')} height="50%" width="50%" alt="instacart" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-04.svg')} height="45%" width="45%" alt="ipg" /></div>
            </div>
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-05.svg')} height="70%" width="70%" alt="lifung" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-06.svg')} height="45%" width="45%" alt="siemens" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-07.svg')} height="55%" width="55%" alt="tomtom" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g2-08.svg')} height="50%" width="50%" alt="worldaware" /></div>
            </div>
        </div>
		<div className="bk-disposition white">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
    </div>
    <div className="sec-custom-b">
        <div className="newviz-content container px-0">
            <div className="row mx-0 px-0">
                <div className="col-12 px-0">
                    <div className="top">
                        <h1>NGOs and International Organizations</h1>
                    </div>
                    <div className="central">
                        <div className="side-left">
                            <p>
                            Located in the heart of Washington DC, we are surrounded by hundreds of organizations involved in social or environmental change, international development or other critical mission. Affordable yet effective, location intelligence should be available to every organization. As a result, we partner with TechChange to teach practitioners quick ways to create visualizations using CARTO, Mapbox and other tools.
                            </p>
                            <p>
                            In cases where customized visualizations are needed, we are here to help. Vizonomy has developed dozens of dashboards for the World Bank, the World Wildlife Fund, the United Nations, Population Action International, Research 4 Development and many others.
                            </p>
                            <p className="light">
                            Every project is unique. We can help point you in the right direction.
                            </p>
                        </div>
                        <div className="side-right">
                            <img className="picture" src={require('../../images/customer/bk-img-03.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="brands container px-0">
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-01.svg')} height="60%" width="60%" alt="result" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-02.svg')} height="45%" width="45%" alt="idb" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-03.svg')} height="45%" width="45%" alt="pai" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-04.svg')} height="55%" width="55%" alt="placetech" /></div>
            </div>
            <div className="row block-b">
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-05.svg')} height="40%" width="40%" alt="county of alameda" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-06.svg')} height="40%" width="40%" alt="fremont" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-07.svg')} height="60%" width="60%" alt="livermore" /></div>
                <div className="col-3"><img className="picture" src={require('../../images/customer/ic-brand-g3-08.svg')} height="70%" width="70%" alt="leandro" /></div>
            </div>
        </div>
		<div className="bk-disposition">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
    </div>
    <div className="sec-fun">
    	<div className="newviz-content-6 container px-0">
    	    <div className="row mx-0 px-0">
    	      <div className="col-6 mx-0 px-0 block-1">
    	        <h2 className="purple-title-2">
    	        Functional Design First
    	        </h2>
    	        <p className="purple-description pad-bottom">
    	        Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
    	        </p>
    	        <div className="op-link">
    	          <a href="https://vizonomy.com/natural-resources" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
    	          <a href="/contact-us" className="link-purple"><span className="op-link-title">Contact Us</span><span className="arrow-purple"></span></a>
    	        </div>
    	      </div>
    	      <div className="col-3 mx-0 px-0 block-2">
    	        <span className="icon-wwd"><span className="ic-df-1"></span></span>
    	        <h4 className="title-5">Design something new</h4>
    	        <p className="purple-description">
    	        <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
    	        </p>
    	        <div className="op-link">
    	          <a href="https://vizonomy.com/user-experience" className="link-purple"><span className="op-link-title">Our Approach</span><span className="arrow-purple"></span></a>
    	        </div>
    	      </div>
    	      <div className="col-3 mx-0 px-0 block-2 block-b">
    	        <span className="icon-wwd"><span className="ic-df-2"></span></span>
    	        <h4 className="title-5">Maintain existing system</h4>
    	        <p className="purple-description">
    	        We’ll <span className="light">review and refactor</span> your code for future improvements.
    	        </p>
    	        <div className="op-link">
    	          <a href="http://vizonomy.com/company" className="link-purple"><span className="op-link-title">About Us</span><span className="arrow-purple"></span></a>
    	        </div>
    	      </div>
    	    </div>
    	</div>
		<div className="bk-disposition">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
  	</div>
  	<div className="rivet-area">
  	  	<div className="top">
  	  	  <img className="picture" src={require('../../images/customer/rivet-04-1.svg')}  alt="logo" />
  	  	</div>
  	  	<div className="bottom">
  	  	  <img className="picture" src={require('../../images/customer/rivet-04-2.svg')}  alt="logo" />
  	  	</div>
  	  	<div className="bk-disposition ">
  	  	  	<div className="container px-0 lines">
  	  	  	  	<div className="row">
  	  	  	  	  	<div className="col-3 lin-a"></div>
  	  	  	  	  	<div className="col-3 lin-b"></div>
  	  	  	  	  	<div className="col-3 lin-c"></div>
  	  	  	  	  	<div className="col-3 lin-d"></div>
  	  	  	  	</div>
  	  	  	</div>
  	  	</div>
  	</div>
  	<div className="sec-g">
      	<div className="newviz-content-7 container">
        	<div className="row mx-0 px-0">
        	  	<div className="col-3 mx-0 px-0 block-1">
        	  	  	<div className="pos-top">
        	  	  	  	<img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
        	  	  	  	<h2 className="purple-title">
        	  	  	  	Designed in Washington DC
        	  	  	  	</h2>
        	  	  	</div>
        	  	  	<div className="brand-year">© Vizonomy 2020</div>
        	  	</div>
        	  	<div className="col-3 mx-0 px-0 block-2">
        	  	  	<div className="menu-item-foot">
        	  	  	  	<h1 className="title">Solutions</h1>
        	  	  	  	<a href="/agriculture">Agriculture</a>
        	  	  	  	<a href="/business-intelligence">Business Intelligence</a>
        	  	  	  	<a href="/climate">Climate</a>
        	  	  	  	<a href="/government">Government</a>
        	  	  	  	<a href="/health">Health</a>
        	  	  	  	<a href="/natural-resources">Natural Resources</a>
        	  	  	  	<a href="/non-profit">Non-Profit</a>
        	  	  	  	<a href="/real-estate">Real Estate</a>
        	  	  	  	<a href="/smart-cities">Smart Cities</a>
        	  	  	</div>
        	  	</div>
        	  	<div className="col-3 mx-0 px-0 block-2">
        	  	  	<div className="menu-item-foot">
        	  	  	  <h1 className="title">Analytics</h1>
					  <a href="#">Drone and Satellite</a>
              		  <a href="#">3D Visualization</a>
              		  <a href="#">Real-Time Analytics</a>
              		  <a href="#">Machine Learning</a>
        	  	  	</div>
        	  	  	<div className="menu-item-foot">
        	  	  	  <h1 className="title">Company</h1>
        	  	  	  <a href="/company">Our Story</a>
        	  	  	  <a href="/customers">Customers</a>
        	  	  	</div>
        	  	</div>
        	  	<div className="col-3 mx-0 px-0 block-2">
        	  	  	<div className="menu-item-foot">
        	  	  	  <h1 className="title">Design</h1>
        	  	  	  <a href="/user-experience">User Experience</a>
        	  	  	  <a href="/gis-web-mapping">GIS and Web Mapping</a>
        	  	  	  <a href="/data-visualization">Data Visualization</a>
        	  	  	  <a href="/mobile-web">Mobile and Web</a>
        	  	  	  <a href="/augmented-reality">Augmented Reality</a>
        	  	  	</div>
        	  	  	<div className="menu-item-foot">
        	  	  	  <h1 className="title">Resources</h1>
        	  	  	  <a href="https://medium.com/vizonomy">Our Blog</a>
        	  	  	  <a href="/contact-us">Contact Us</a>
        	  	  	</div>
        	  	</div>
        	</div>
      	</div>
      	<div className="newviz-content-7-mobile">
      	  	<div className="row mx-0 px-0">
      	  	  <div className="col-12 mx-0 px-0 block-1">
      	  	    <div className="pos-top">
      	  	      <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
      	  	      <h2 className="purple-title">
      	  	      Designed in Washington DC
      	  	      </h2>
      	  	    </div>
      	  	  </div>
      	  	</div>
      	  	<div className="row mx-0 px-0 section-menu-foot">
      	  	  <div className="col-6 mx-0 px-0 block-2">
      	  	    <div className="menu-item-foot">
      	  	      <h1 className="title">Solutions</h1>
      	  	      <a href="/agriculture">Agriculture</a>
      	  	      <a href="/business-intelligence">Business Intelligence</a>
      	  	      <a href="/climate">Climate</a>
      	  	      <a href="/government">Government</a>
      	  	      <a href="/health">Health</a>
      	  	      <a href="/natural-resources">Natural Resources</a>
      	  	      <a href="/non-profit">Non-Profit</a>
      	  	      <a href="/real-estate">Real Estate</a>
      	  	      <a href="/smart-cities">Smart Cities</a>
      	  	    </div>
      	  	    <div className="menu-item-foot">
      	  	      <h1 className="title">Analytics</h1>
      	  	      <a href="#">Drone and Satellite</a>
              	  <a href="#">3D Visualization</a>
                  <a href="#">Real-Time Analytics</a>
                  <a href="#">Machine Learning</a>
      	  	    </div>
      	  	  </div>
      	  	  <div className="col-6 mx-0 px-0 block-2">
      	  	    <div className="menu-item-foot">
      	  	      <h1 className="title">Company</h1>
      	  	      <a href="/company">Our Story</a>
      	  	      <a href="/customers">Customers</a>
      	  	    </div>
      	  	    <div className="menu-item-foot">
      	  	      <h1 className="title">Design</h1>
      	  	      <a href="/user-experience">User Experience</a>
      	  	      <a href="/gis-web-mapping">GIS and Web Mapping</a>
      	  	      <a href="/data-visualization">Data Visualization</a>
      	  	      <a href="/mobile-web">Mobile and Web</a>
      	  	      <a href="/augmented-reality">Augmented Reality</a>
      	  	    </div>
      	  	    <div className="menu-item-foot">
      	  	      <h1 className="title">Resources</h1>
      	  	      <a href="https://medium.com/vizonomy">Our Blog</a>
      	  	      <a href="/contact-us">Contact Us</a>
      	  	    </div>
      	  	  </div>
      	  	</div>
      	  	<div className="row mx-0 px-0">
      	  	  <div className="col-12 mx-0 px-0 block-1">
      	  	    <div className="brand-year">© Vizonomy 2020</div>
      	  	  </div>
      	  	</div>
      	</div>
		<div className="bk-disposition">
    		<div className="container px-0 lines">
    		  	<div className="row">
    		  	  	<div className="col-3 lin-a"></div>
    		  	  	<div className="col-3 lin-b"></div>
    		  	  	<div className="col-3 lin-c"></div>
    		  	  	<div className="col-3 lin-d"></div>
    		  	</div>
    		</div>
    	</div>
  </div>
</div>
);
}
export default Customer;
