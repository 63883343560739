import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import * as d3 from 'd3';

let currentZScale = 4;
let zoom;
let svg;
const ChartDemo = () => {
  let category = ['睡眠', '朝ご飯', '洗濯', 'テレビ', 'YouTube', 'ゲーム', '昼ごはん', 'ランニング', 'パソコン', '夜ご飯', 'お風呂']
  let LEFTDOTSVALUE = 'dragginglines';
  let RIGHTDOTVALUE = 'dragginglines';
  
  const colorScale = {
    completed: '#5E5FE2',
    active: '#047CD7',
    notStarted: '#D4D2D9',
    delayed: '#F5575C'
  };
  let rawData = [
    {
      id: 'A',
      date: moment('2022/08/11'),
      schedule: [
        { objectId: 100059, categoryNo: 0, from: moment('2022/07/02 00:00:00'), to: moment('2022/07/21 07:00:00'), status: 'completed', name: 'Work Request' },
        { objectId: 100059, categoryNo: 5, from: moment('2022/07/22 08:30:00'), to: moment('2022/08/17 10:00:00'), status: 'completed', name: 'Start Up' },
        { objectId: 100059, categoryNo: 1, from: moment('2022/06/22 07:30:00'), to: moment('2022/07/01 08:30:00'), status: 'active', name: 'Draft' },
        { objectId: 100059, categoryNo: 4, from: moment('2022/08/18 08:30:00'), to: moment('2022/11/10 10:00:00'), status: 'active', name: 'Work Plan' }
      ]
    },
    {
      id: 'B',
      date: moment('2022/08/15'),
      schedule: [
        { objectId: 8905, categoryNo: 0, from: moment('2022/07/02 00:00:00'), to: moment('2022/07/24 07:00:00'), status: 'active', name: 'Draft' },
        { objectId: 8905, categoryNo: 5, from: moment('2022/08/27 08:30:00'), to: moment('2022/09/08 10:00:00'), status: 'delayed', name: 'Conceptual Design' },
        { objectId: 8905, categoryNo: 1, from: moment('2022/07/25 07:30:00'), to: moment('2022/08/12 08:30:00'), status: 'delayed', name: 'Initial Funding' },
        { objectId: 8905, categoryNo: 4, from: moment('2022/08/13 08:30:00'), to: moment('2022/08/26 10:00:00'), status: 'completed', name: 'Consultant Procurement' }
      ]
    },
    {
      id: 'C',
      date: moment('2022/08/19'),
      schedule: [
        { objectId: 31599, categoryNo: 0, from: moment('2022/08/11 00:00:00'), to: moment('2022/08/21 07:00:00'), status: 'delayed', name: 'Draft' },
        { objectId: 31599, categoryNo: 1, from: moment('2022/08/22 08:30:00'), to: moment('2022/08/31 10:00:00'), status: 'completed', name: 'Preliminary Design' },
        { objectId: 31599, categoryNo: 2, from: moment('2022/09/01 07:30:00'), to: moment('2022/09/10 08:30:00'), status: 'completed', name: 'Final Design' },
        { objectId: 31599, categoryNo: 3, from: moment('2022/09/11 08:30:00'), to: moment('2022/09/22 10:00:00'), status: 'active', name: 'Construction Contracting' }
      ]
    },
    {
      id: 'D',
      date: moment('2022/08/11'),
      schedule: [
        { objectId: 98000, categoryNo: 0, from: moment('2022/07/11 00:00:00'), to: moment('2022/07/21 07:00:00'), status: 'active', name: 'Substantial Completion' },
        { objectId: 98000, categoryNo: 5, from: moment('2022/08/11 08:30:00'), to: moment('2022/08/29 10:00:00'), status: 'active', name: 'Closed' },
        { objectId: 98000, categoryNo: 1, from: moment('2022/07/22 07:30:00'), to: moment('2022/08/10 08:30:00'), status: 'notStarted', name: 'Construction' },
        { objectId: 98000, categoryNo: 4, from: moment('2022/06/11 08:30:00'), to: moment('2022/07/10 10:00:00'), status: 'notStarted', name: 'Draft' }
      ]
    },
    {
      id: 'E',
      date: moment('2022/08/15'),
      schedule: [
        { objectId: 189990, categoryNo: 0, from: moment('2022/07/01 00:00:00'), to: moment('2022/07/20 07:00:00'), status: 'notStarted', name: 'Work Request' },
        { objectId: 189990, categoryNo: 5, from: moment('2022/08/27 08:30:00'), to: moment('2022/10/02 10:00:00'), status: 'active', name: 'Start-Up ' },
        { objectId: 189990, categoryNo: 1, from: moment('2022/07/21 07:30:00'), to: moment('2022/08/12 08:30:00'), status: 'delayed', name: 'Work Request' },
        { objectId: 189990, categoryNo: 4, from: moment('2022/08/13 08:30:00'), to: moment('2022/08/26 10:00:00'), status: 'active', name: 'Work-Plan' }
      ]
    },
    {
      id: 'F',
      date: moment('2022/08/19'),
      schedule: [
        { objectId: 6800, categoryNo: 0, from: moment('2022/07/27 00:00:00'), to: moment('2022/08/12 07:00:00'), status: 'active', name: 'Draft ' },
        { objectId: 6800, categoryNo: 1, from: moment('2022/08/13 08:30:00'), to: moment('2022/08/28 10:00:00'), status: 'active', name: 'Work Request' },
        { objectId: 6800, categoryNo: 2, from: moment('2022/08/29 07:30:00'), to: moment('2022/09/10 08:30:00'), status: 'delayed', name: 'Conceptual Desig' },
        { objectId: 6800, categoryNo: 3, from: moment('2022/09/11 08:30:00'), to: moment('2022/09/22 10:00:00'), status: 'active', name: 'Preliminary Desig' }
      ]
    }
  ];
  let datasets = rawData.map((el) => {
    return {...el, schedule: el.schedule.map((sch) => {
      return {...sch, id: el.id}
    }).sort(function(a,b) { return a.from - b.from}) };
  });
  let barHeight = 28;
  const [xLeft, setXLeft] = useState(0);
  let width = 900, height = (barHeight + 10) * (rawData.length + 1)
    let padding = { top: 40, right: 10, bottom: 10, left: 75 }
    let offsetBar = 18;
    const paddleWidth = 6;
    const dotsLength = 3;
    const dotsLengthHalf = (dotsLength / 2);
    const offsetX1 = paddleWidth/2-dotsLengthHalf;
    const offsetX2 = paddleWidth/2+dotsLengthHalf;
    const barWidth = width / 31;
    let newXLeft = (xLeft+barWidth) % width;
    let newXRight = (newXLeft+barWidth) % width;
    let today = new Date();
    

  useEffect(() => {
    setXLeft(newXLeft);
    let dotsLeft;
    let dotsRight;
    let fromData = datasets.map((ds) => ds.schedule).flat().sort(function(a, b) { return a.from - b.from});
    let toData = datasets.map((ds) => ds.schedule).flat().sort(function(a, b) { return a.to - b.to});
    let timelineStartTime = moment(fromData[0].from.startOf('month')).subtract(6, 'months');
    let timelineEndTime = moment(toData[toData.length - 1].to).add(6, 'months').startOf('month');
    let timelineStartTime2 = moment(fromData[0].from.startOf('year')).subtract(1, 'years');
    let timelineEndTime2 = moment(toData[toData.length - 1].to).add(1, 'years').startOf('year');
    svg = d3.select('#timeline-chart')
    .append('svg')
    .attr('width', width)
    .attr('height', height)

    let backgroundRects = svg.append("g")
      .append("rect")
      .attr("y", d => 150)
      .attr("height", d => barHeight)
      .attr("x", d => 0)
      .attr("width", d => width)
     .attr('class', 'backgroundRecthidden')

    let xScale = d3.scaleTime()
      .domain([timelineStartTime, timelineEndTime])
      .range([padding.left, width - padding.right])

    let x2Scale = d3.scaleTime()
      .domain([timelineStartTime2, timelineEndTime2])
      .range([padding.left, width - padding.right]);

    let yScale = d3.scaleBand()
      .domain(datasets.map(d => d.id))
      .range([padding.top +5, height - padding.bottom]);

    let chartHeight = height - padding.top - padding.bottom

    let xAxis2 = d3.axisTop(xScale)
      .tickSize(-chartHeight+100)
      .ticks(d3.timeYear.every(1))
      .tickFormat(d3.timeFormat('%Y'));
    
      let xAxis = d3.axisTop(xScale)
      .ticks(d3.timeMonth.every(1))
      .tickSize(-chartHeight)
      .tickFormat(d3.timeFormat("%b"), function(d) { return d.getMonth(); })

      let yAxis = d3.axisLeft(yScale)
      .ticks(12)
      .tickSize(width)
      .tickFormat('')

    let gX = svg.append('g')
      .attr('transform', 'translate(' + 0 + ',' + (padding.top) + ')')
      .attr("class", "topHeader")
      .call(xAxis)

    let gX2 = svg.append('g')
      .attr('transform', 'translate(' + 0 + ',' + (padding.top- 20) + ')')
      .attr("class", "topHeaderYear")
      .style("text-anchor", "start")
      .call(xAxis2);

      svg.append('g')
      .attr('transform', 'translate(' + (width - padding.left) + ',' + -22 + ')')
      .attr("class", "topHeaderYear")
      .style("text-anchor", "start")
      .call(yAxis);

    let dateDispFormat = 'Y M D'
    let chartCenterY = padding.top + chartHeight / 2

    let scheduleG = svg.append("g")
      .selectAll("g")
      .data(datasets)
      .enter().append("g").attr("class", 'jurisdiction')
      .selectAll()
        .data(d => { return d.schedule });

    let todayline = scheduleG.join("line")
      .attr("x1", function () { return xScale(today) })  //<<== change your code here
      .attr("y1", padding.top)
      .attr("x2", function () { return xScale(today) })  //<<== and here
      .attr("y2", height + padding.top - padding.bottom)
      .style("stroke-width", 2)
      .style("stroke", "#047CD7")
      .style("fill", "none");

    //   let svgDefinitions = svg.append("defs");
    //   let linearGradientTextLabel = svgDefinitions.append("linearGradient");

    //   linearGradientTextLabel
    //   .attr("id", "textBg")
    //   .attr("x1", "0%")
    //   .attr("x2", "100%")
    //   .attr("y1", "0")
    //   .attr("y2", "0");

    // linearGradientTextLabel.append("stop")
    //   .attr("offset", "0%")
    //   .attr("stop-color", "red")
    // linearGradientTextLabel.append("stop")
    //   .attr("offset", "10%")
    //   .attr("stop-color", "red")
    //   linearGradientTextLabel.append("stop")
    //   .attr("offset", "10%")
    //   .attr("stop-color", "green")
    // linearGradientTextLabel.append("stop")
    //   .attr("offset", "100%")
    //   .attr("stop-color", "green")

    let scheduleRects = scheduleG.join('rect')
      .attr('id',function (d) {return `${d.id}_${d.categoryNo}`})
      .attr('class', 'stackedbar')
      // .attr('id', (d) => d.name.split(' ').join(''))
      .attr('rx', 12)
      .attr('ry', 12)
      .attr('x', function (d) { return xScale(d['from']) })
      .attr('y', function(d) { return yScale(d['id'])})
      .attr('width', function (d) { return xScale(d['to']) - xScale(d['from']) })
      .attr('height', barHeight)
      .attr('fill', function (d) { return colorScale[d.status] })

      let scheduleRectsCenter = scheduleG.join('rect')
      .attr('id',function (d) {return `${d.id}_${d.categoryNo}_center`})
      .attr('class', 'stackedbarCenter')
      // .attr('id', (d) => d.name.split(' ').join(''))
      .attr('x', function (d) { return xScale(d['from'])})
      .attr('y', function(d) { return yScale(d['id']) +1})
      .attr('width', function (d) { return xScale(d['to']) - xScale(d['from'])})
      .attr('height', barHeight - 2)
      .attr('fill', function (d) { return colorScale[d.status] })
     // .attr("fill", "url(#textBg)")

    let rectNames = scheduleG.join('text')
    .attr('id', (d) => 'text_' + d.name.replace( / +/g, '')+ '_'+d.objectId)
    .attr('class', 'labels')
    .style("fill", "white")
    .style('font-size', 12)
    .attr('x', function (d) { return xScale(d['from'])})
    .attr('y', function(d) { return yScale(d['id']) + yScale.bandwidth() / 2 })
    .attr('width', function (d) { return xScale(d['to']) - xScale(d['from']) })
    .text((d) => d.name)
    


    let groupLeftDots = scheduleG.join('g').attr('class', LEFTDOTSVALUE).attr('id', (d) => { return `${d.id}_${d.categoryNo}_left`});
    let groupRightDots = scheduleG.join('g').attr('class', RIGHTDOTVALUE).attr('id', (d) => `${d.id}_${d.categoryNo}_right`);

      let h = yScale.bandwidth();
    dotsLeft = groupLeftDots.append('line')
      .attr('id',function (d) {return `${d.id}_${d.categoryNo}_left`})
      .attr('class', 'dragginglines')
      .attr("x1", function (d) { return xScale(d['from']) - dotsLengthHalf+3; })
      .attr("x2", function (d) { return xScale(d['from']) - dotsLengthHalf+3; })
      .attr("y1", function(d) { return yScale(d['id']) + h})
      .attr("y2", function(d) { return yScale(d['id']) + h + 8})

    dotsRight = groupRightDots.append('line')
      .attr('id',function (d) {return `${d.id}_${d.categoryNo}_right`})
      .attr('class', 'dragginglines')
      .attr("x1", function (d) { return xScale(d['to']) - dotsLengthHalf-3; })
      .attr("x2", function (d) { return xScale(d['to']) + dotsLengthHalf-3; })
      .attr("y1", function(d) { return yScale(d['id']) + h})
      .attr("y2", function(d) { return yScale(d['id']) + h + 8})

    
    let zoomedXScale = xScale
    let calctodayX = function (d) { return zoomedXScale(today) }
    let calcScheduleX = function (d) { return zoomedXScale(d['from']) }
    let calcScheduleXInner = function (d) { return zoomedXScale(d['from']) +15}
    let calcScheduleXCenter = function (d) { return zoomedXScale(d['from']) + offsetBar }
    let calcScheduleWidth = function (d) { return zoomedXScale(d['to']) - zoomedXScale(d['from']) }
    let calcScheduleWidthInner = function (d) { return zoomedXScale(d['to']) - zoomedXScale(d['from']) -30}
    let calcLeftX1dots = function (d) { return zoomedXScale(d['from']) - dotsLengthHalf+8 }
    let calcScheduleWidthText = function (d) { return zoomedXScale(d['to']) - zoomedXScale(d['from']) - 1 };
    let calcLeftX2dots = function (d) { return zoomedXScale(d['from']) + dotsLengthHalf+3 }
    let calcRightX1dots = function (d) { return zoomedXScale(d['to'])  - dotsLengthHalf-6 }
    let calcRightX2dots = function (d) { return zoomedXScale(d['to'])  + dotsLengthHalf-3 }
    //let calcSchedulexdots = function (d) { return zoomedXScale(d['to']) - zoomedXScale(d['from']) }
    
    let makeRoundTime = function (time) {
      let roundDaysStr = ('0' + String(Math.round(time.minute() / 5) * 5)).slice(-2)
      if (roundDaysStr === '60') {
        roundDaysStr = '00'
        time.add(1, 'hours')
      }
      return moment(time.format('YYYY/MM/DD HH:' + roundDaysStr.slice(-2) + ':00'))
    }
    let dragStartDots = function (d) {
      d3.event.sourceEvent.stopPropagation()
      d3.select(this)
        .classed('dragging', true)
        .style('opacity', 1)
    }
    let dragStart = function (d) {
      d3.event.sourceEvent.stopPropagation()
      d3.select(this)
        .classed('dragging', true)
        .style('opacity', 0.7)
    }
    let dragEndRect = function (d) {
      d['from'] = makeRoundTime(d['from'])
      d['to'] = makeRoundTime(d['to'])
      d3.select(this)
        .classed('dragging', false)
        .style('opacity', 0.7)
        .attr('x', calcScheduleX)
        .attr('width', calcScheduleWidth);
      updateRects();
    }
    let rectDrag = d3.drag()
      .on('start', dragStart)
      .on('drag', function (d) {
        let between = d['to'].diff(d['from'], 'days')
        let fromTime = moment(zoomedXScale.invert(zoomedXScale(d['from']) + d3.event.dx))
        let toTime = moment(fromTime).add(between, 'days')
    
        if (timelineStartTime.diff(fromTime) > 0) return;
        else if (timelineEndTime.diff(toTime) < 0) return;
    
        d['from'] = fromTime
        d['to'] = toTime
        d3.select(this).attr('x', calcScheduleX);
        for(const child of this.parentNode.children) {
          const currentClass = child.getAttribute('class');
          if (currentClass === LEFTDOTSVALUE || currentClass === RIGHTDOTVALUE) {
            moveChildrenDots(child);
          }
        }
        moveOtherRects(d3.event.dx, d.categoryNo, d.id);
        updateRects();
      })
      .on('end', dragEndRect);
    let moveOtherRects = function(moveX, dataId, groupId) {
      let currentDataset = datasets.filter((d) => d.id === groupId)[0];
      currentDataset.schedule.forEach(sch => {
        if ( sch.categoryNo !== dataId ) {
          let fromTime = moment(zoomedXScale.invert(zoomedXScale(sch['from']) + moveX));
          let between = sch['to'].diff(sch['from'], 'days');
          let toTime = moment(fromTime).add(between, 'days')
          sch['from'] = fromTime;
          sch['to'] = toTime;
        }
      });
    }
    
    let moveOtherRectsByDirection = function(moveX, dataId, direction, groupId) {
      let currentDataset = datasets.filter((d) => d.id === groupId)[0];
      let indexOfCurrent = currentDataset.schedule.findIndex((sch) => sch.categoryNo === dataId);
      let init = 0;
      let end = indexOfCurrent;
      if(!direction) {
        init = indexOfCurrent + 1;
        end = currentDataset.schedule.length;
      }
      for(let i = init; i < end; ++i) {
        let sch = currentDataset.schedule[i];
        let fromTime = moment(zoomedXScale.invert(zoomedXScale(sch['from']) + moveX));
        let between = sch['to'].diff(sch['from'], 'days');
        let toTime = moment(fromTime).add(between, 'days')
        sch['from'] = fromTime;
        sch['to'] = toTime;
      }
    }
    let moveChildrenDots = function(elem) {
      for( const [index, child] of [...elem.children].entries()) {
        let h = yScale.bandwidth();
        d3.select(child).attr('x1', d3.select(child).classed('dragginglines') ? calcRightX1dots : calcLeftX1dots)
        d3.select(child).attr('x2', d3.select(child).classed('dragginglines') ? calcRightX1dots : calcLeftX1dots)
        d3.select(child).attr('y1', (d) => { return yScale(d['id']) + h })
        d3.select(child).attr('y2', (d) => { return yScale(d['id']) + h + 8})
      }
    }
    let dotsDragFunction = function(d) {
      let currentDots = d3.select(this).classed('dragging', true);
      let isLeftDots = d3.select(this).attr('id').includes('left');
      let fromTime;
      let between;
      let toTime;
      if (isLeftDots){
        fromTime = moment(zoomedXScale.invert(zoomedXScale(d['from']) + d3.event.dx));
        between = d['to'].diff(fromTime, 'days');
        toTime = moment(fromTime).add(between, 'days');
        if (timelineStartTime.diff(fromTime) > 0) return;
        else if (timelineEndTime.diff(toTime) < 0) return;
        d['from'] = fromTime
      } else {
        toTime = moment(zoomedXScale.invert(zoomedXScale(d['to']) + d3.event.dx));
        between = d['from'].diff(toTime, 'days');
        fromTime = moment(toTime).add(between, 'days');
        if (timelineStartTime.diff(toTime) > 0) return;
        else if (timelineEndTime.diff(fromTime) < 0) return;
        d['to'] = toTime
      }
      moveOtherRectsByDirection(d3.event.dx, d.categoryNo, isLeftDots, d.id);
      //d['to'] = toTime
      // d3.select(this).attr('x', calcScheduleX);
      moveChildrenDots(this);
      updateRects();
    }
    let dragEndDots = function (d) {
      d3.select(this)
      .classed('dragging', false)
    }


    let dotsDrag = d3.drag()
      .on('start', dragStartDots)
      .on('drag', dotsDragFunction)
      .on('end', dragEndDots);



    scheduleRects.style('cursor', 'move')
      // .style('mix-blend-mode', 'multiply')
      .call(rectDrag);

    scheduleRectsCenter.style('cursor', 'move')
    .call(rectDrag);

    groupLeftDots.style('cursor', 'ew-resize')
      // .style('mix-blend-mode', 'multiply')
      .style("stroke-linecap", "round")
      .call(dotsDrag);
    groupRightDots.style('cursor', 'ew-resize')
    // .style('mix-blend-mode', 'multiply')
    .style("stroke-linecap", "round")
    .call(dotsDrag);

    const dotme = (text) => {
      text.each((d) => {
        const completeLabel = `${d['name']}`;
        const idText = 'text_' + d['name'].replace( / +/g, '')+ '_'+d.objectId;
        const textElem = d3.select(`#${idText}`);
        const rectElem = d3.select(`#${d.id}_${d.categoryNo}`)
        const padding = 15;
        const totalWidth = rectElem.attr('width') - padding;
        textElem.text(completeLabel);
        let it = 1;
        if (totalWidth < 25) {
          textElem.text('');
        } else {
          if (textElem.node().getComputedTextLength() > totalWidth - padding) {
            while (textElem.node().getComputedTextLength() > totalWidth - padding && it < completeLabel.length) {
              textElem.text(completeLabel.slice(0, -it) + '...');
              it++;
            }
          }
        }
        
        
          // const selfText = d3.select(this);
          // const words = { word: `${selfText.text()}` };
          // console.log('text length', selfText.node().getComputedTextLength());
          // if (words.word !== '' && words.word !== '...') {
          //   const containin_rect = d3.select(`#${words.word.split(' ').join('')}`);
          //   console.log('contaning', containin_rect, words.word.split(' ').join(''));
          //   // falta tener el text complete original para luego agregar letras ahorita se borra con al quitar el text
          //   // capaz obtener dato y con id obtener el text a modificar
          //   if(containin_rect.size() !== 0) {
          //     const width = containin_rect.attr('width');
          //     const padding = 5;
          //     let textlength = selfText.node().getComputedTextLength();
          //     let textLiteral = selfText.text();
          //     console.log('textlength', textlength, selfText.text(), width, containin_rect.attr('width'));
          //     while(textlength > (width - 5) && textLiteral.length > 0) {
          //       console.log('length', textlength, width);
          //       textLiteral = textLiteral.slice(0, -1);
          //       selfText.text(textLiteral);
          //       textlength = selfText.node().getComputedTextLength(); 
          //     }
          //   }
          // }
      });
  }

  
    let updateRects = function () {
      todayline.attr('x1', calctodayX)
      todayline.attr('x2', calctodayX)
      scheduleRects.attr('x', calcScheduleX)
      .attr('width', calcScheduleWidth)
      scheduleRectsCenter.attr('x', calcScheduleXInner)
      .attr('width', calcScheduleWidthInner)
      rectNames.attr('x', calcScheduleXCenter)
      .attr('width', calcScheduleWidthText);
      dotsLeft.attr('x1',calcLeftX1dots)
      .attr('x2', calcLeftX1dots)
      d3.selectAll('.labels').call(dotme);
      
        let h = yScale.bandwidth() - barHeight;
        dotsLeft.attr('x1',calcLeftX1dots)
        .attr('x2', calcLeftX1dots)
        .attr('y1', (d) => { return yScale(d['id']) + h  })
        .attr('y2', (d) => { return yScale(d['id']) + h + 13})
        dotsRight.attr('x1',calcRightX1dots)
        .attr('x2', calcRightX1dots)
        .attr('y1', (d) => { return yScale(d['id']) + h })
        .attr('y2', (d) => { return yScale(d['id']) + h + 13})

    }
    scheduleRectsCenter.on("click", function() {
      d3.selectAll('.stackedbarClicked').attr('class', 'stackedbar' )
      d3.selectAll('.dragginglinesonclick').attr('class', 'dragginglines' )

      d3.select(`#${d3.event.target.id.slice(0, -7)}_right`).attr('class', 'dragginglinesonclick' )
      d3.select(`#${d3.event.target.id.slice(0, -7)}_left`).attr('class', 'dragginglinesonclick' )
      if(d3.event.target.id.includes('center')){
        d3.select(`#${d3.event.target.id.slice(0, -7)}`).attr('class', 'stackedbarClicked' )
      } else {
        d3.select(`#${d3.event.target.id}`).attr('class', 'stackedbarClicked' )
      }
      backgroundRects.attr('y', d => d3.event.target.y.animVal.value).attr('class', 'backgroundRectvisible')
    d3.event.stopPropagation();
  });
    scheduleRects.on("click", function() {
        d3.selectAll('.stackedbarClicked').attr('class', 'stackedbar' )
        d3.selectAll('.dragginglinesonclick').attr('class', 'dragginglines' )

        d3.select(`#${d3.event.target.id}_right`).attr('class', 'dragginglinesonclick' )
        d3.select(`#${d3.event.target.id}_left`).attr('class', 'dragginglinesonclick' )

        backgroundRects.attr('y', d => d3.event.target.y.animVal.value).attr('class', 'backgroundRectvisible')
      d3.event.stopPropagation();
    });
    svg.on("click", function() {
      d3.selectAll('.dragginglinesonclick').attr('class', 'dragginglines' )
      d3.selectAll('.backgroundRectvisible').attr('class', 'backgroundRecthidden' )
      if(d3.event.target.id.includes('center')){
        d3.selectAll('.stackedbarClicked').attr('class', 'stackedbar' )
      } else {
        d3.select('.stackedbarClicked').attr('class', 'stackedbar' )
      }
    });
    let zoomed = function () {
      zoomedXScale = d3.event.transform.rescaleX(xScale);
      gX.call(xAxis.scale(zoomedXScale));
      gX2.call(xAxis2.scale(zoomedXScale));
      updateRects();
      currentZScale = d3.event.transform.k;
    }
    zoom = d3.zoom()
      .scaleExtent([0.5, 20])
      .translateExtent([[0, 0], [width, 0]])
      .on('zoom', zoomed);
    svg.call(zoom);
    svg.transition().call(zoom.scaleBy, currentZScale)
  }, []);
  const moveZoom = (type) => {
    const adder = type === 'in' ? 1.4 : 0.7;
    svg.transition().call(zoom.scaleBy, adder);
  }
  return (
    <div>
      <button onClick={() => moveZoom('in')}>ZOOM IN </button> 
      <button onClick={() => moveZoom('out')}>ZOOM OUT </button>
      <div id="timeline-chart" />
    </div>
    
  )
}

export default ChartDemo;