/* import React from 'react'; */
import React, { useEffect  } from 'react';

function AugmentedReality (props) {
  useEffect(()=>{
    document.title = "Augmented Reality | Vizonomy";
  },[]);
return (
<div className="AugmentedReality">
<div className="sec-nr">
    <div className="bk-grid">
    </div>
    <div className="content-nr">
	  	  <div className="navi">
          <div className="side-a">
            <h3><span className="ic-gw"></span><span className="title">Augmented Reality</span></h3>
          </div>
          <div className="side-b">
          <a href="/user-experience">User Experience</a>
          <a href="/gis-web-mapping">GIS & Web Mapping</a>
          <a href="/data-visualization" >Data Visualization</a>
          <a href="/mobile-web" >Mobile & Web</a>
          <a href="/augmented-reality" className="active">Augmented Reality</a>
          </div>
        </div>
        <div className="row mx-0 my-0 cover-detail">
          <div className="col-9">
            <h1 className="title-principal">Immersive digital mapping experiences in the real world</h1>
            <p className="description">
            Visualize real time, relevant data, such as vehicular occupancy and flood risk, through an intuitive 3D spatial interface for a unified view of smart cities, campuses, commercial properties, shopping malls, airports, museums, and more.
            </p>
            <div class="op-link">
              <a href="/natural-resources" class="btn-blue">
                <span class="op-link-title">Projects</span><span class="arrow-white"></span>
              </a>
              <a href="/contact-us" class="link-blue">
                <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
              </a>
            </div>
          </div>
          <div className="col-4">
          </div>
        </div>
    </div>
    <div className="bk-image">
      <div className="show-picture">
        <img src={require('../../images/augmented-r/bk-img-ar-01.png')} />
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
  </div>
  <div className="sec-shaping-vision ">
    <div className="centralize-data">
      <div className="row">
        <div className="col-12">
          <h4>Augmented Reality</h4>
          <h1>
          Shaping a vision, delivering software, creating world class experiences
          </h1>
          <p>
          We partner with forward thinking agencies and brands globally to create connected design experiences. Those include delivering products centered on data visualization, geospatial analysis, mobile and web, and augmented reality. 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="sec-browsing-in-detail">
    <div className="navigation-area">
      <div className="list-items">
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/augmented-r/bk-img-caro-01.png')}/>
            </div>
            <div className="description">
              <a href="/gis-web-mapping"><h4>GIS Mapping</h4></a>
              <p>Develop fluid and immersive mapping experiences with robust data pipelines across APIs, databases, and more.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img  src={require('../../images/augmented-r/bk-img-caro-02.png')}/>
            </div>
            <div className="description">
              <a href="/user-experience"><h4>User Experience</h4></a>
              <p>Craft user roles & workflows with our dedicated design sprint team. Empathize with your audience & be strategic in your design decisions.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img src={require('../../images/augmented-r/bk-img-caro-03.png')}/>
            </div>
            <div className="description">
              <a href="/data-visualization"><h4>Data Visualization</h4></a>
              <p>Offer beautiful visualizations from the most commonplace to the most unique. Simplify complexity with custom dashboards.</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="icard">
            <div className="picture">
              <img src={require('../../images/augmented-r/bk-img-caro-04.png')}/>
            </div>
            <div className="description">
              <a href="/mobile-web"><h4>Mobile and Web</h4></a>
              <p>Responsive design leveraging the most modern web frameworks from React, Node, and Bootstrap.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
	<div className="sec-fdf">
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Functional Design First
            </h2>
            <p className="purple-description pad-bottom">
            Throughout the process of any project, our team works with you to research new technologies and processes, model prototypes and learn from your audience, and then realize those results through beautiful products.
            </p>
            <div className="op-link op1">
              <a href="/natural-resources" className="btn-purple"><span className="op-link-title">Our Work</span><span className="arrow-white"></span></a>
              <a href="/contact-us" className="link-sky-blue"><span className="op-link-title">Contact Us</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-1"></span></span>
            <h4 className="title-5">Design something new</h4>
            <p className="purple-description">
            <span className="light">Ideate your vision</span> with our design team. We’ll bring it to life with open source.
            </p>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-df-2"></span></span>
            <h4 className="title-5">Maintain existing system</h4>
            <p className="purple-description">
            We’ll <span className="light">review and refactor</span> your code for future improvements.
            </p>

          </div>
        </div>
      </div>
      <div className="bk-grid">
      </div>
    </div>
  </div>
	<div className="rivet-05">
	  <img className="picture" src={require('../../images/analytics/rivet-lines-sky-blue.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>	
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
</div>
);
}
export default AugmentedReality;