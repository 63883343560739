import React, { useEffect, useState } from 'react';

function About (props) {

return (
<div className="About">
  
</div>
);
}
export default About;