/* import React from 'react'; */
import React, { useState,useEffect } from 'react';

function GisWebMapping (props) {
  useEffect(()=>{
    document.title = "GIS + Web Mapping | Vizonomy";
  },[]);
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);
return (
<div className="GisWebMapping">
  <div className="sec-gwp">
    <div className="bk-grid"></div>
    <div className="content-gwp">
      <div className="navi">
        <div className="side-a">
          <h3><span className="ic-gw"></span><span className="title">GIS & Web Mapping</span></h3>
        </div>
        <div className="side-b">
          <a href="/user-experience">User Experience</a>
          <a href="/gis-web-mapping" className="active">GIS & Web Mapping</a>
          <a href="/data-visualization">Data Visualization</a>
          <a href="/mobile-web">Mobile & Web</a>
          <a href="/augmented-reality">Augmented Reality</a>
        </div>
        <div className="side-c">
          <a href="/user-experience">User Experience</a>
          <a href="/gis-web-mapping" className="active">GIS & Web Mapping</a>
          <a href="/data-visualization">Data Visualization</a>
          <a href="/mobile-web">Mobile & Web</a>
          <a href="/augmented-reality">Augmented Reality</a>
        </div>
      </div>
      <div className="row mx-0 my-0">
        <div className="col-9">
          <h1 className="title-principal">Discover hidden value in your data: location intelligence</h1>
          <p className="description">
          From tools and algorithms that collect data from mobile devices, crawl the web for demographic information, or connect to internal systems, we prepare your data for location intelligence.
          </p>
          <div class="op-link">
            <a href="https://vizonomy.com/natural-resources" class="btn-blue">
              <span class="op-link-title">Our Work</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-blue">
              <span class="op-link-title">Contact Us</span><span class="arrow-blue"></span>
            </a>
          </div>
        </div>
        <div className="col-4">
        </div>
      </div>
    </div>
    <div className="main-top-bk-2">
      <video className="video-fragment" autoPlay muted loop preload >
        <source src={require('../../images/main/movie-01.mp4')} type="video/mp4"/>
      </video>
    </div>
  </div>
  <div className="sec-tech-stack">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4 className="sm-title">Technology Stack</h4>
          <h1 className="md-title">Scalable and reliable spatial infrastructure</h1>
          <p className="desc-txt">
          From hosting your data, to developing the map and connecting it all together. Leverage cost-effective and performant solutions to power your maps. We often work with CARTO, Mapbox, Leaflet, GeoServer, Mapillary and Tableau.
          </p>
          <p className="small">
          <a href="https://medium.com/vizonomy/carto-partners-with-vizonomy-to-scale-location-intelligence-solutions-9fda327ac302" className="light">CARTO</a> partners with Vizonomy for all projects in North America.  Together we provide a full-stack solution with beautiful visualizations, a reliable spatial engine, and ample support. 
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/analytics/bk-analytics-01.png')}  alt="" />
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
  <div className="sec-brands">
    <div className="container">
      <div className="brands">
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-01.svg')} height="50%" width="50%" alt="carto" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-02.svg')} height="60%" width="60%" alt="mapbox" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-03.svg')} height="60%" width="60%"alt="geoserver" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-04.svg')} height="50%" width="50%" alt="leaflet" /></div>
        </div>
        <div className="row">
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-05.svg')} height="60%" width="60%" alt="tableau" /></div>
          <div className="col-3"><img className="picture size-30" src={require('../../images/analytics/ic_brand-06.svg')} height="15%" width="15%" alt="db" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-07.svg')} height="60%" width="60%" alt="cesium" /></div>
          <div className="col-3"><img className="picture" src={require('../../images/analytics/ic_brand-08.svg')} height="50%" width="50%" alt="maoillary" /></div>
        </div>
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
  <div className="sec-case-studies">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4 className="sm-title">
          Case Studies
          </h4>
          <h1 className="md-title">
          Craft unique mapping experiences
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <p className="desc-txt">
          We'll make your data come to life through lightweight and highly customizable maps that offer more than just a modern look. They can also be used for analysis: whether it's viewing changes over time, filtering layers, or determining relationships between features. Benefits include:
          </p>
        </div>
        <div className="col-6">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Vector-based tiles for precise map styling</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">SQL commands for spatial analysis</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Synchronous connections to any database</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Realtime connections to other browser components e.g. graphics, tables, notification triggers, or pop-ups</span></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="area-img">
            <img className="picture" src={require('../../images/analytics/bk-analytics-02.png')} alt="" />
          </div>
          <h4 className="subtitle">
          Agriculture dashboard for start-up
          </h4>
          <p className="sm-desc-txt">
          Vizonomy developed a tablet-optimized ag management platform that enables farmers to use GPS locations for precise note-taking. All notes, imagery, and sensor data are stored in a cloud account and visualized through interactive maps, graphics, and tables.
          </p>
        </div>
        <div className="col-6">
          <div className="area-img">
            <img className="picture" src={require('../../images/analytics/bk-analytics-03.png')} alt="" />
          </div>
          <h4 className="subtitle">
          Health platform for international agency
          </h4>
          <p className="sm-desc-txt">
          Developed in 2019, Vizonomy partnered with the World Health Organization to visualize HPV coverage across countries worldwide. The platform allows users to compare coverage and visualize indicators across regions.
          </p>
        </div>
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
  <div className="sec-styling">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4 className="sm-title">Styling</h4>
          <h1 className="md-title">Custom basemaps for your organizational brand</h1>
          <p className="desc-txt">
          Working with Mapbox GL, CARTO VL, or other vector libraries, we offer full design control over your map. Whether it's styling a road, a body of water, a district, or just a project asset, we help your map fit seamlessly with your brand.
          </p>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/analytics/bk-analytics-04.png')}  alt="" />
      </div>
    </div>
    <div className="container px-0 chk-area">
      <div className="loud">We personalize for:</div>
      <div className="row">
        <div className="col-3">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Custom imagery</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Attribute-based styling</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Precise labeling</span></span>
          </div>
        </div>
        <div className="col-3">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Unique typography</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Zoom-based styling</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Add 3d features</span></span>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
  <div className="sec-health-db">
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h4 className="sm-title">
            Case Study
          </h4>
          <h1 className="md-title">
          AIDSVu health dashboard
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <p className="desc-txt">
          AIDSVu is an interactive online mapping tool that visualizes the impact of the HIV epidemic on communities across the United States.  View city, county, state, regional, and national profiles with local HIV/AIDS statistics, national comparison charts, and local resources.
          </p>
        </div>
        <div className="col-6">
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Identify patterns over time or across regions</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Print maps on high resolution</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Share URLs for any custom map permutation</span></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="area-img">
            <img className="picture" src={require('../../images/analytics/bk-analytics-06.png')}  alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
  <div className="rivet-cs">
    <img className="picture" src={require('../../images/analytics/rivet-blue-dark.svg')}  alt="" />
    <div className="bk-grid">
    </div>
  </div>
  <div className="sec-marketing">
    <div className="container px-0">
      <div className="row">
        <div className="col-6">
          <h4>Case Study</h4>
          <h1>Visualizing marketing operations and socio-economic data</h1>
          <p>
          Vizonomy worked with IPG Global to develop a desktop and mobile friendly interface to help assess current revenue from thousands of billboard campaigns across the U.S. By clicking each billboard, users are able to understand surrounding pedestrian and vehicular traffic, demographic information and current contracts for an ROI analysis. Other benefits include: 
          </p>
          <div className="group-chk">
            <span className="chk"><span className="ic-chk"></span><span className="tit">Custom map and interface branding</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Data pipeline creation and storage system</span></span>
            <span className="chk"><span className="ic-chk"></span><span className="tit">Feature export to CSV or PDF</span></span>
          </div>
          <div class="op-link">
            <a href="/user-experience" class="btn-blue">
              <span class="op-link-title">Our Approach</span><span class="arrow-white"></span>
            </a>
            <a href="/contact-us" class="link-white">
              <span class="op-link-title">Contact Us</span><span class="arrow-white"></span>
            </a>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    </div>
    <div className="bk-imagen">
      <div className="show-picture">
        <img className="picture" src={require('../../images/analytics/bk-analytics-07.png')}  alt="" />
      </div>
    </div>
  </div>
  <div className="sec-ready">{/* sec-fdf */}
    <div className="bk-disposition">
      <div className="newviz-content-6">
        <div className="row mx-0 px-0">
          <div className="col-6 mx-0 px-0 block-1">
            <h2 className="purple-title-2">
            Ready to get started? Get in touch.
            </h2>
            <p className="desc-text">
            Let's turn your data into purposeful insight.
            </p>
            <div className="op-link op1">
              <a href="/contact-us" className="btn-blue"><span className="op-link-title">Contact Us</span><span className="arrow-white"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-ux"></span></span>
            <h4 className="title-5">User experience</h4>
            <p className="purple-description">
            Craft delightful user experiences for your digital products. Solve real problems through beautiful interfaces.
            </p>
            <div className="op-link op2">
              <a href="/user-experience" className="link-sky-blue"><span className="op-link-title">Our Approach</span><span className="arrow-blue"></span></a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <span className="icon-wwd"><span className="ic-mobileweb"></span></span>
            <h4 className="title-5">Mobile & web</h4>
            <p className="purple-description">
            Design for all devices through a flexible, responsive design. Learn more how we use React, Bootstrap, Angular and other libraries.
            </p>
            <div className="op-link op2">
              <a href="/mobile-web" className="link-sky-blue"><span className="op-link-title">Design Details</span><span className="arrow-blue"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid-area">
      <div className="bk-grid">
      </div>
    </div>
  </div>
<div className="rivet-touch">
<img className="picture" src={require('../../images/analytics/rivet-lines-sky-blue.svg')}  alt="" />
<div className="bk-grid">
</div>
</div>
  <div className="sec-g">
    <div className="bk-disposition">
      <div className="newviz-content-7">
        <div className="row mx-0 px-0">
          <div className="col-3 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
          </div>
          <div className="col-3 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="newviz-content-7-mobile">
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="pos-top">
              <img className="picture" src={require('../../images/main/ic-logo-dark-vizonomy.svg')}  alt="logo" />
              <h2 className="purple-title">
              Designed in Washington DC
              </h2>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0 section-menu-foot">
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Solutions</h1>
              <a href="/agriculture">Agriculture</a>
              <a href="/business-intelligence">Business Intelligence</a>
              <a href="/climate">Climate</a>
              <a href="/government">Government</a>
              <a href="/health">Health</a>
              <a href="/natural-resources">Natural Resources</a>
              <a href="/non-profit">Non-Profit</a>
              <a href="/real-estate">Real Estate</a>
              <a href="/smart-cities">Smart Cities</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Analytics</h1>
              <a href="#">Drone and Satellite</a>
              <a href="#">3D Visualization</a>
              <a href="#">Real-Time Analytics</a>
              <a href="#">Machine Learning</a>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 block-2">
            <div className="menu-item-foot">
              <h1 className="title">Company</h1>
              <a href="/company">Our Story</a>
              <a href="/customers">Customers</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Design</h1>
              <a href="/user-experience">User Experience</a>
              <a href="/gis-web-mapping">GIS and Web Mapping</a>
              <a href="/data-visualization">Data Visualization</a>
              <a href="/mobile-web">Mobile and Web</a>
              <a href="/augmented-reality">Augmented Reality</a>
            </div>
            <div className="menu-item-foot">
              <h1 className="title">Resources</h1>
              <a href="https://medium.com/vizonomy">Our Blog</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 mx-0 px-0 block-1">
            <div className="brand-year">© Vizonomy 2020</div>
          </div>
        </div>
      </div>
    </div>
    <div className="bk-grid">
    </div>
  </div>
</div>
);
}
export default GisWebMapping;