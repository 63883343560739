/* import React from 'react'; */
import React, { useState,useEffect } from 'react';

function RealTimeAnalytics (props) {
  useEffect(()=>{
    document.title = "Real Time Analytics | Vizonomy";
  },[]);
return (
<div className="RealTimeAnalytics">
</div>
);
}
export default RealTimeAnalytics;