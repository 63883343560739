import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Main from './components/Main';
import CustomRoute from './components/CustomRoute';
import Solutions from './components/Solutions';
import Analytics from './components/Analytics';
import About from './components/About';
import Contact from './components/Contact';
import Company from './components/Company';
import Customer from './components/Customer';
import Partners from './components/Partners';
import OurApproach from './components/OurApproach';
import CaseStudies from './components/CaseStudies';
import Climate from './components/Climate';
import Mobility from './components/Mobility';
import Agriculture from './components/Agriculture';
import BusinessIntelligence from './components/BusinessIntelligence';
import Government from './components/Government';
import Health from './components/Health';
import NaturalResources from './components/NaturalResources';
import Nonprofit from './components/Nonprofit';
import SmartCities from './components/SmartCities';
import UserExperience from './components/UserExperience';
import GisWebMapping from './components/GisWebMapping';
import DataVisualization from './components/DataVisualization';
import MobileWeb from './components/MobileWeb';
import AugmentedReality from './components/AugmentedReality';
import DroneSatellite from './components/DroneSatellite';
import RealTimeAnalytics from './components/RealTimeAnalytics';
import MachineLearning from './components/MachineLearning';
import TDVisualization from './components/TDVisualization';
import RealState from './components/RealState';
import ReactGA from 'react-ga';
import DotChart from './components/charts/DotChart';
import ChartDemo from './components/charts/ChartDemo';
function App() {

ReactGA.initialize('UA-175692199-1');
ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <BrowserRouter>
      <Switch>
        <Redirect from='/' to='/main' exact />
        <CustomRoute path="/main" component={Main} type="menu"/>
        <CustomRoute path="/agriculture" component={Agriculture} type="menu-light"/>
        <CustomRoute path="/business-intelligence" component={BusinessIntelligence} type="menu-light"/>
        
        <CustomRoute path="/about" component={About} type="menu"/>
        <CustomRoute path="/contact-us" component={Contact}  type="menu-light"/>
        <CustomRoute path="/company" component={Company} type="menu-light"/>
        <CustomRoute path="/customers" component={Customer} type="menu"/>
        <CustomRoute path="/partners" component={Partners} type="menu"/>
        <CustomRoute path="/our-approach" component={OurApproach} type="menu"/>
        <CustomRoute path="/case-studies" component={CaseStudies} type="menu"/>
        <CustomRoute path="/climate" component={Climate} type="menu-light"/>
        <CustomRoute path="/mobility" component={Mobility} type="menu"/>
        
        <CustomRoute path="/government" component={Government} type="menu-light"/>
        <CustomRoute path="/health" component={Health} type="menu-light"/>
        <CustomRoute path="/natural-resources" component={NaturalResources} type="menu-light"/>
        <CustomRoute path="/non-profit" component={Nonprofit} type="menu-light"/>
        <CustomRoute path="/real-estate" component={RealState} type="menu-light"/>
        <CustomRoute path="/smart-cities" component={SmartCities} type="menu-light"/>
        <CustomRoute path="/user-experience" component={UserExperience} type="menu-light"/> 
        <CustomRoute path="/gis-web-mapping" component={GisWebMapping} type="menu-light"/>
        <CustomRoute path="/data-visualization" component={DataVisualization} type="menu-light"/>
        <CustomRoute path="/mobile-web" component={MobileWeb} type="menu-light"/>
        <CustomRoute path="/augmented-reality" component={AugmentedReality} type="menu-light"/>
        <CustomRoute path="/drone-satellite" component={DroneSatellite} type="menu"/>
        <CustomRoute path="/real-time-analytics" component={RealTimeAnalytics} type="menu"/>
        <CustomRoute path="/machine-learning" component={MachineLearning} type="menu"/>
        <CustomRoute path="/3d-visualization" component={TDVisualization} type="menu"/>

        <CustomRoute path="/solutions" component={Solutions} type="menu-light"/>
        <CustomRoute path="/analytics" component={Analytics} type="menu"/>
        <CustomRoute path="/hiddenzxc/lollipopchart" component={DotChart} type="chart"/>
        <CustomRoute path="/hiddenzxc/chartdrag" component={ChartDemo} type="chart"/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
