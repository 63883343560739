
import * as d3 from 'd3';
import React, { useEffect } from 'react';
import { dataDot, colorScale } from './dataDot';

const DotChart = () => {

  useEffect(() => {
    var margin = {top: 10, right: 30, bottom: 30, left: 30},
    width = 1600 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

// append the svg object to the body of the page
var svg = d3.select("#my_dataviz")
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

  let datas = dataDot

  let arrayForCirclesAndLines = [];
  for (var i = 0; i < datas[0].data.length; i++) {
      arrayForCirclesAndLines.push(i);
  }

      let svgDefinitions = svg.append("defs");
      svg.selectAll("defs")
          .data(datas)
          .enter()
      let completedtoActive = svgDefinitions.append("linearGradient");
      completedtoActive
        .attr("id", "Completed_Active")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0")
        .attr("y2", "0");
      completedtoActive.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", '#5E5FE2')
      completedtoActive.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", '#047CD7')

      let completedtoDelayed = svgDefinitions.append("linearGradient");
      completedtoDelayed
        .attr("id", "Completed_Delayed")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0")
        .attr("y2", "0");
      completedtoDelayed.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", '#5E5FE2')
      completedtoDelayed.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", '#F5575C')

      let ActivetoNotStarted = svgDefinitions.append("linearGradient");
      ActivetoNotStarted
        .attr("id", "Active_NotStarted")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0")
        .attr("y2", "0");
      ActivetoNotStarted.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", '#047CD7')
      ActivetoNotStarted.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", '#D4D2D9')

      let ActivetoDelayed = svgDefinitions.append("linearGradient");
      ActivetoDelayed
        .attr("id", "Active_Delayed")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0")
        .attr("y2", "0");
      ActivetoDelayed.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", '#047CD7')
      ActivetoDelayed.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", '#F5575C')

      let delayedtoNotStarted = svgDefinitions.append("linearGradient");
      delayedtoNotStarted
        .attr("id", "Delayed_NotStarted")
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0")
        .attr("y2", "0");
      delayedtoNotStarted.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", '#F5575C')
      delayedtoNotStarted.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", '#D4D2D9')

  // Add X axis
  var x = d3.scaleLinear()
    .domain([0, 16])
    .range([ margin.left, width]);
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .attr('class', 'xAxisDotChart')
    .call(d3.axisBottom(x))

  // Y axis
  var y = d3.scaleBand()
    .range([ 0, height ])
    .domain(datas.map(function(d) { return d.name; }))
    .padding(1);
  svg.append("g")
    .attr('class', 'YAxisDotChart')
    .call(d3.axisLeft(y))

  // Lines
  arrayForCirclesAndLines.forEach(r => {
   
    if ( r < arrayForCirclesAndLines.length - 1) {

      svg.selectAll("myline")
        .data(datas)
        .enter()
        .append("rect")
          .attr("x", function(d) { return x(r); })
          .attr("width", function(d) { return x(r+1) - x(r); })
          .attr("y", function(d) { return y(d.name); })
          .attr("height", 2)
          .attr("stroke", function(d) {      
            let currentStatus = d.data[r].status.replace(/\s+/g, '');
            let nextStatus = d.data[r+1].status.replace(/\s+/g, '');
            return ( (currentStatus === nextStatus) ?
            colorScale[d.data[r].status] :
            (`url(#${currentStatus}_${nextStatus})`))
          })
          // .attr("stroke", "url(#textBg)")

          .attr("stroke-width", "4px")
    }
  });
  const radius = 12;
  arrayForCirclesAndLines.forEach(r => {
    let circles = svg.selectAll("mycircle")
    .data(datas)
    .enter();

    circles.append("circle")
    .attr("cx", function(d) { return x(r); })
    .attr("cy", function(d) { return y(d.name); })
    .attr("r", radius)
    .style("fill", function(d) { return colorScale[d.data[r].status]});
    circles.append("circle")
    .attr("cx", function(d) { return x(r); })
    .attr("cy", function(d) { return y(d.name); })
    .attr("r", radius - 1)
    .style("fill", function(d) { return 'white'});
    circles.append("circle")
    .attr("cx", function(d) { return x(r); })
    .attr("cy", function(d) { return y(d.name); })
    .attr("r", radius - 3)
    .style("fill", function(d) { return colorScale[d.data[r].status]})
    svg.selectAll("myText")
    .data(datas)
    .enter()
    .append("text")
    .attr('class', 'circletext')
    .text(function(d) { return d.data[r].value; })
    .attr("x", function(d) { const offset = +d.data[r].value > 9 ? x(r) - radius/2 : x(r) - radius/4; return offset;})
    .attr("y", function(d) { return y(d.name) + radius/3; })
  });
  },[]);
  return (
    <div>
      <div id="my_dataviz"></div>
    </div>
  )
}

export default DotChart;